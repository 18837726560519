import axios from "axios";
import { remoteConfig } from "firebase";
import { API_URL, SSO_LOGIN } from "./APIConstants";

export const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 2000,
    headers: {
        'Accept': "application/json",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

let config = {
    headers: {
        "Authorization": localStorage.getItem("jwt_token") ? "Bearer " + localStorage.getItem("jwt_token") : undefined
    }
}

export function setConfig(jwtToken) {
    config = {
        headers: {
            "Authorization": jwtToken ? "Bearer " + jwtToken : undefined
        }
    }
}

export async function getApi(URL, onSuccess, onFail, skipErrorHandling) {
    await axiosInstance.get(URL, config).then((response) => {
        if (onSuccess) {
            if (skipErrorHandling === true) {
                onSuccess(response.data);
            } else {
                handleError(response.data, onSuccess, onFail);
            }
        }
    }).catch((error) => {
        if (onFail) {
            onFail(error);
        }
    });
}

export async function ssoLoginDetails(ssoConfig, onSuccess, onFail) {
    await axiosInstance.get(SSO_LOGIN, ssoConfig).then((response) => {
        onSuccess(response.data);
    }).catch((error) => {
        onFail();
    });
}

export async function postApi(URL, onSuccess, onFail, body) {
    await axiosInstance.post(URL, body, config).then((response) => {
        if (onSuccess) {
            // onSuccess(response.data);
            handleError(response.data, onSuccess, onFail);
        }
    }).catch((error) => {
        if (onFail) {
            onFail(error);
        }
    });
}


function handleError(data, onSuccess, onFail) {
    if (data.responseCode === 0 || data.result.responseCode === 0) {
        onSuccess(data)
    } else {
        onFail(data.ResponseMessage)
    }
}

export async function putApi(URL, onSuccess, onFail, body) {
    await axiosInstance.put(URL, body, config).then((response) => {
        if (onSuccess) {
            // onSuccess(response.data);
            handleError(response.data, onSuccess, onFail);
        }
    }).catch((error) => {
        if (onFail) {
            onFail(error);
        }
    });
}