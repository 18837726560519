export const dashboardSummaryData = {
  // Open: {
  //   current: 9,
  //   average: 10,
  // },
  NEW: {
    current: 9,
    average: 3,
  },
  ASSIGNED: {
    current: 9,
    average: 10,
  },
  "RESPONDED": {
    current: 9,
    average: 10,
  },
  CLOSED: {
    current: 9,
    average: 10,
  },
  // CANCELED: {
  //   current: 9,
  //   average: 10,
  // },
  // REJECTED: {
  //   current: 9,
  //   average: 10,
  // },
};

export const dashboard30DaysData = {
  "2022-01-01": {
    new: 30,
    responded: 20,
  },
  "2022-01-02": {
    new: 30,
    responded: 20,
  },
  "2022-01-03": {
    new: 30,
    responded: 20,
  },
  "2022-01-04": {
    new: 30,
    responded: 20,
  },
  "2022-01-05": {
    new: 30,
    responded: 20,
  },
  "2022-01-06": {
    new: 30,
    responded: 20,
  },
  "2022-01-07": {
    new: 30,
    responded: 20,
  },
  "2022-01-08": {
    new: 30,
    responded: 20,
  },
  "2022-01-09": {
    new: 30,
    responded: 20,
  },
  "2022-01-10": {
    new: 30,
    responded: 20,
  },
  "2022-01-11": {
    new: 30,
    responded: 20,
  },
  "2022-01-12": {
    new: 30,
    responded: 20,
  },
  "2022-01-13": {
    new: 30,
    responded: 20,
  },
  "2022-01-14": {
    new: 30,
    responded: 20,
  },
  "2022-01-15": {
    new: 10,
    responded: 20,
  },
  "2022-01-16": {
    new: 15,
    responded: 10,
  },
  "2022-01-17": {
    new: 15,
    responded: 25,
  },
  "2022-01-18": {
    new: 5,
    responded: 30,
  },
  "2022-01-19": {
    new: 15,
    responded: 30,
  },
  "2022-01-20": {
    new: 15,
    responded: 30,
  },
  "2022-01-21": {
    new: 10,
    responded: 40,
  },
  "2022-01-22": {
    new: 30,
    responded: 40,
  },
  "2022-01-23": {
    new: 30,
    responded: 10,
  },
  "2022-01-24": {
    new: 20,
    responded: 40,
  },
  "2022-01-25": {
    new: 15,
    responded: 40,
  },
  "2022-01-26": {
    new: 40,
    responded: 40,
  },
  "2022-01-27": {
    new: 50,
    responded: 40,
  },
  "2022-01-28": {
    new: 30,
    responded: 10,
  },
  "2022-01-29": {
    new: 15,
    responded: 35,
  },
  "2022-01-30": {
    new: 30,
    responded: 10,
  },
  "2022-01-31": {
    new: 45,
    responded: 10,
  },
};

export const dashboardIncindentActivity = [
  {
    id: 1,
    name: "David Hopkins",
    avatar: "avatar",
    email: "david_hopkins@gmail.com",
    dueDate: "2022-02-02 14:00:25",
    status: "NEW",
    priority: "MEDIUM",
    assignTo: [
      {
        "userId": 1,
        "username": "admin",
        "firstName": "ashah",
        "lastName": "shah",
        "email": "ashah@assisttsolutions.com",
        "mobile": "9999999999",
        "title": "",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      },
      {
        "userId": 2,
        "username": "pshah",
        "firstName": "Punnet",
        "lastName": "Shah",
        "email": "pshah@assisttsolutions.com",
        "mobile": "9988998899",
        "title": "Lead Developer",
        "department": "Mobile Tech",
        "address": "Gurukul, Ahmedabad",
        "authorities": {
          "ASSETS": "User",
          "COMPLIANCE": "Manager"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      },
      {
        "userId": 3,
        "username": "ajlan",
        "firstName": "Ajlan",
        "lastName": "Okman",
        "email": "ajlan@assisttsolutions.com",
        "mobile": "8899889989",
        "title": "Tech Head",
        "department": "Management",
        "address": "New York",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      }
    ]
  },
  {
    id: 1,
    name: "David Hopkins",
    avatar: "avatar",
    email: "david_hopkins@gmail.com",
    status: "OPEN",
    dueDate: "2022-02-02 14:00:25",
    priority: "LOW",
    assignTo: [
      {
        "userId": 1,
        "username": "admin",
        "firstName": "ashah",
        "lastName": "shah",
        "email": "ashah@assisttsolutions.com",
        "mobile": "9999999999",
        "title": "",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      }
    ]
  },
  {
    id: 1,
    name: "David Hopkins",
    avatar: "avatar",
    email: "david_hopkins@gmail.com",
    status: "CANCELLED",
    priority: "HIGH",
    dueDate: "2022-02-02 14:00:25",
    assignTo: [
      {
        "userId": 1,
        "username": "admin",
        "firstName": "ashah",
        "lastName": "shah",
        "email": "ashah@assisttsolutions.com",
        "mobile": "9999999999",
        "title": "",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      }
    ]
  },
  {
    id: 1,
    name: "David Hopkins",
    avatar: "avatar",
    email: "david_hopkins@gmail.com",
    status: "COMPLETED",
    priority: "MEDIUM",
    dueDate: "2022-02-02 14:00:25",
    assignTo: [
      {
        "userId": 1,
        "username": "admin",
        "firstName": "ashah",
        "lastName": "shah",
        "email": "ashah@assisttsolutions.com",
        "mobile": "9999999999",
        "title": "",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      }
    ]
  },
  {
    id: 1,
    name: "David Hopkins",
    avatar: "avatar",
    email: "david_hopkins@gmail.com",
    status: "COMPLETED",
    priority: "HIGH",
    dueDate: "2022-02-02 14:00:25",
    assignTo: [
      {
        "userId": 1,
        "username": "admin",
        "firstName": "ashah",
        "lastName": "shah",
        "email": "ashah@assisttsolutions.com",
        "mobile": "9999999999",
        "title": "",
        "authorities": {
          "ALL": "Admin"
        },
        "userType": "Admin",
        "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
        "gender": "male"
      }
    ]
  },
];

export const dashboardTourOverview = {
  SCHEDULED: {
    current: 9,
    average: 10,
  },
  "IN PROGRESS": {
    current: 9,
    average: 10,
  },
  COMPLETED: {
    current: 9,
    average: 10,
  },
  INCOMPLETE: {
    current: 9,
    average: 10,
  },
};

export const dashboard30DaysTourData = {
  "2022-01-01": {
    new: 30,
    responded: 20,
  },
  "2022-01-02": {
    new: 30,
    responded: 20,
  },
  "2022-01-03": {
    new: 30,
    responded: 20,
  },
  "2022-01-04": {
    new: 30,
    responded: 20,
  },
  "2022-01-05": {
    new: 30,
    responded: 20,
  },
  "2022-01-06": {
    new: 30,
    responded: 20,
  },
  "2022-01-07": {
    new: 30,
    responded: 20,
  },
  "2022-01-08": {
    new: 30,
    responded: 20,
  },
  "2022-01-09": {
    new: 30,
    responded: 20,
  },
  "2022-01-10": {
    new: 30,
    responded: 20,
  },
  "2022-01-11": {
    new: 30,
    responded: 20,
  },
  "2022-01-12": {
    new: 30,
    responded: 20,
  },
  "2022-01-13": {
    new: 30,
    responded: 20,
  },
  "2022-01-14": {
    new: 30,
    responded: 20,
  },
  "2022-01-15": {
    new: 10,
    responded: 20,
  },
  "2022-01-16": {
    new: 15,
    responded: 10,
  },
  "2022-01-17": {
    new: 15,
    responded: 25,
  },
  "2022-01-18": {
    new: 5,
    responded: 30,
  },
  "2022-01-19": {
    new: 15,
    responded: 30,
  },
  "2022-01-20": {
    new: 15,
    responded: 30,
  },
  "2022-01-21": {
    new: 10,
    responded: 40,
  },
  "2022-01-22": {
    new: 30,
    responded: 40,
  },
  "2022-01-23": {
    new: 30,
    responded: 10,
  },
  "2022-01-24": {
    new: 20,
    responded: 40,
  },
  "2022-01-25": {
    new: 15,
    responded: 40,
  },
  "2022-01-26": {
    new: 40,
    responded: 40,
  },
  "2022-01-27": {
    new: 50,
    responded: 40,
  },
  "2022-01-28": {
    new: 30,
    responded: 10,
  },
  "2022-01-29": {
    new: 15,
    responded: 35,
  },
  "2022-01-30": {
    new: 30,
    responded: 10,
  },
  "2022-01-31": {
    new: 45,
    responded: 10,
  },
};
