import {
  SET_RESIDENT_DATA
  } from "../actions/ResidentActions";
  
  const initialState = {};
  
  const residentReducer = function(state = initialState, action) {
    switch (action.type) {
      case SET_RESIDENT_DATA: {
        return {
          ...state,
          residentList: action.data,
          activePath: action.activePath
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default residentReducer;
