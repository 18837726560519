import {
    SET_COMPANY_DATA,
    COMPANY_SET_PROFILE_SETTING,
    COMPANY_SET_CUSTOM_PROFILE_FIELD
  } from "../actions/CompanyActions";
  
  const initialState = {};
  
  const companyReducer = function(state = initialState, action) {
    switch (action.type) {
      case SET_COMPANY_DATA: {
        return {
          ...state,
          ...action.data
        };
      }
      case COMPANY_SET_PROFILE_SETTING: {
        return {
          ...state,
          profileSettings: action.data
        };
      }
      case COMPANY_SET_CUSTOM_PROFILE_FIELD: {
        return {
          ...state,
          profileCustomFields: action.data
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default companyReducer;
  