import {
  SET_FACILITY_SITE_FOR_COMPLIANCE,
  SELECT_SITE_FOR_COMPLIANCE,
  SET_ASSESSMENT_LIST,
  ASSESSMENT_SHOW_DETAIL_VIEW,
  ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_LIST_INTIATE,
  ADD_ASSESSMENT_INTIATE,
  ADD_ASSESSMENT_FAILURE,
  SET_ASSESSMENT_DETAIL,
  SET_INSPECTION_LIST,
  INSPECTION_LIST_INTIATE,
  INSPECTION_LIST_FAILURE,
  ADD_INSPECTION_FAILURE,
  ADD_INSPECTION_INTIATE,
  INSPECTION_SHOW_DETAIL_VIEW,
  SET_VIOLATIONS,
  SET_VIOLATION_TYPE,
  SET_SELECTED_VIOLATION_TYPE,
  COMPLIANCE_DASHBOARD_ACTIVITY_LOG,
  COMPLIANCE_INCIDENT_LIST_INTIATE,
  COMPLIANCE_INCIDENT_LIST_FAILURE,
  COMPLIANCE_SET_INCIDENT_LIST,
  COMPLIANCE_SET_INCIDENT_DETAILS,
  COMPLIANCE_SET_SECTION
} from "../actions/ComplianceActions";

const initialState = {};

const complianceReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_FACILITY_SITE_FOR_COMPLIANCE: {
      return {
        ...state,
        facilitySites: action.data,
        selectedSite: action.data ? action.data[0] : undefined
      };
    }
    case SELECT_SITE_FOR_COMPLIANCE: {
      return {
        ...state,
        selectedSite: action.selectedSite,
      };
    }
    case SET_ASSESSMENT_LIST: {
      return {
        ...state,
        assessment: action.data,
        loading: false,
      };
    }
    case ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_LIST_INTIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_ASSESSMENT_INTIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_SHOW_DETAIL_VIEW: {
      return {
        ...state,
        selectedTemplate: action.data
      };
    }
    case SET_ASSESSMENT_DETAIL: {
      return {
        ...state,
        assessmentDetail: action.data
      }
    }
    case SET_INSPECTION_LIST: {
      return {
        ...state,
        inspections: action.data,
        loading: false,
      };
    }
    case INSPECTION_LIST_INTIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case INSPECTION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_INSPECTION_INTIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_INSPECTION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case INSPECTION_SHOW_DETAIL_VIEW: {
      return {
        ...state,
        selectedInspection: action.data
      };
    }
    case SET_VIOLATION_TYPE: {
      return {
        ...state,
        violationTypes: action.data
      };
    }
    case SET_VIOLATIONS: {
      return {
        ...state,
        violations: action.data
      };
    }
    case SET_SELECTED_VIOLATION_TYPE: {
      return {
        ...state,
        selectedViolationType: action.data
      };
    }
    case COMPLIANCE_DASHBOARD_ACTIVITY_LOG: {
      return {
        ...state,
        activityLogs: action.data,
      };
    }
    case COMPLIANCE_SET_INCIDENT_LIST: {
      return {
        ...state,
        incidents: action.data,
        loading: false,
      };
    }
    case COMPLIANCE_INCIDENT_LIST_INTIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case COMPLIANCE_INCIDENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case COMPLIANCE_SET_INCIDENT_DETAILS: {
      return {
        ...state,
        loading: false,
        incidentDetail: action.data
      };
    }
    case COMPLIANCE_SET_SECTION: {
      return {
        ...state,
        selectedSection: action.data
      }
    }
    default: {
      return state;
    }
  }
};

export default complianceReducer;