import Constants from 'app/services/Constants';
import {
  API_GET_ASSETS_TYPE,
  API_CREATE_ASSETS_TYPE,
  API_GET_ASSETS,
  API_GET_ASSETS_DETAIL,
  API_ASSETS_CUSTOM_FIELD_ANSWERS,
  API_ASSETS_CUSTOM_FIELD,
  API_ADD_ASSETS,
  API_CREATE_ASSETS_INVENTORY,
  API_GET_ASSETS_INVENTORY,
  API_GET_USERS,
  API_GET_FACILITY,
  API_GET_ASSETS_FACILITY,
  API_ADD_ASSETS_FACILITY
} from "app/services/APIConstants";
import { getApi, postApi } from "app/services/ApiService";
import {
  NotificationManager
} from "react-notifications";

export const SET_ASSETS = "SET_ASSETS"
export const SET_ASSETS_INVENTORY = "SET_ASSETS_INVENTORY";
export const SET_ASSETS_DETAILS = "SET_ASSETS_DETAILS"
export const SELECT_ASSETS = "SELECT_ASSETS";
export const ADD_ASSSET_INITIATE = "ADD_ASSSET_INITIATE";
export const ADD_ASSSET_FAILURE = "ADD_ASSSET_FAILURE";
export const LOAD_ASSET = "LOAD_ASSET";
export const LOAD_ASSET_INITIATE = "LOAD_ASSET_INITIATE";
export const SAVE_CUSTOM_FIELD_RESPONDED = "SAVE_CUSTOM_FIELD_RESPONDED";
export const SAVE_CUSTOM_FIELD_INITIATE = "SAVE_CUSTOM_FIELD_INITIATE";
export const PEOPLE_SET_USERS = "PEOPLE_SET_USERS";
export const ASSET_SET_FACILITY_DATA = "ASSET_SET_FACILITY_DATA";
export const SET_FACILITY_ASSET_LIST = "SET_FACILITY_ASSET_LIST";
export const ADD_FACILITY_ASSET_LIST = "ADD_FACILITY_ASSET_LIST";
export const ADD_FACILITY_ASSET_LIST_INITIATE = "ADD_FACILITY_ASSET_LIST_INITIATE";

const onFail = (error) => {
  NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);
}

export function loadAssetInventory() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: SET_ASSETS_INVENTORY,
        data: data.assetsInventory
      })
    }
    getApi(API_GET_ASSETS_INVENTORY, onSuccess, onFail);
  };
};


export function loadAssetTypes(inventoryId) {
  return (dispatch, getState) => {

    const onSuccess = (data) => {
      dispatch({
        type: SET_ASSETS,
        data: data.assetsType
      })
    }
    getApi(API_GET_ASSETS_TYPE+"?inventoryId="+inventoryId, onSuccess, onFail);
  };
};

export function saveAssets(body) {
  return (dispatch, getState) => {

    const onSuccess = (data) => {
      dispatch(loadAssetTypes())
    }
    postApi(API_CREATE_ASSETS_TYPE, onSuccess, onFail, body);
  };
};

export function saveAssetsInventory(body) {
  return (dispatch, getState) => {

    const onSuccess = (data) => {
      dispatch(loadAssetInventory())
    }
    postApi(API_CREATE_ASSETS_INVENTORY, onSuccess, onFail, body);
  };
};

export function loadAssets(typeId) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_ASSET_INITIATE
    });

    let assetType = getState().assets.assetsType || [];
    const onSuccess = (data) => {
      delete data.result;
      /*if (assetType.length === 0) {
        assetType[0] = {
          AssetTypeId:typeId,
          assets: data
        }
      } else {
        assetType = assetType.map(e=> {
          if(e.AssetTypeId == typeId) {
            e.assets = data;
          }
          return e;
        });
      }*/
      dispatch({
        type: LOAD_ASSET,
        data,
        activeTypeId: typeId,
      })
    }
    const url = `${API_GET_ASSETS}?typeId=${typeId}`
    getApi(url, onSuccess, onFail);
  };
};

export function loadAssetTree(assetId) {
  return (dispatch, getState) => {

    const onSuccess = (data) => {
      dispatch({
        type: SET_ASSETS_DETAILS,
        data: data.assetDetails,
      })
    }
    const url = `${API_GET_ASSETS_DETAIL}?assetId=${assetId}`
    getApi(url, onSuccess, onFail);
  };
};


export function selectAsset(assets) {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_ASSETS,
      data: {
        assetId: assets.assetId,
        typeId: assets.typeId,
      },
    })
  };
};
export function saveCustomFieldsAnswers(body) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      NotificationManager.success(Constants.CUSTOMFIELDS_UPDATED, Constants.SUCCESS, 3000);
    }
    postApi(API_ASSETS_CUSTOM_FIELD_ANSWERS, onSuccess, onFail, body);
  };
};
export function saveCustomFields(body) {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE_CUSTOM_FIELD_INITIATE
    });
    const onFail = (error) => {
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);
      dispatch({
        type: SAVE_CUSTOM_FIELD_RESPONDED
      })
    }
    const onSuccess = (data) => {
      NotificationManager.success(Constants.CUSTOMFIELDS_UPDATED, Constants.SUCCESS, 3000);
      dispatch({
        type: SAVE_CUSTOM_FIELD_RESPONDED
      })
    }
    postApi(API_ASSETS_CUSTOM_FIELD, onSuccess, onFail, body);
  };
};
export function addAssets(body, assetsType) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_ASSSET_INITIATE
    });
    const onFail = () => {
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);
      dispatch({
        type: ADD_ASSSET_FAILURE
      });
    }
    const onSuccess = (data) => {
      NotificationManager.success(body.id ? Constants.ASSET_UPDATED : Constants.ASSET_ADDED, Constants.SUCCESS, 3000);
      dispatch(loadAssets(body.typeId, assetsType));
    }
    postApi(API_ADD_ASSETS, onSuccess, onFail, body);

  };
};

export function loadPeople() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (data) => {
      dispatch({
        type: PEOPLE_SET_USERS,
        data: data
      })
    }
    getApi(API_GET_USERS, onSuccess, onFail);
  };
}
export function loadFacilities(facilityType = "SITE") {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (response) => {
      dispatch({
          type: ASSET_SET_FACILITY_DATA,
          data: response.facilities
      })
    }
    const url = `${API_GET_FACILITY}?facilityType=${facilityType}`;
    getApi(url, onSuccess, onFail);
  };
}

  export function loadAssetFacilities(assetId, facilityId, userId) {
    return (dispatch, getState) => {
      const onFail = (error) => {
        console.log("data ======> ", error)
      }
      const onSuccess = (response) => {
        dispatch({
            type: SET_FACILITY_ASSET_LIST,
            data: response.assetsAllocationResponseList
        })
      }
      let url = `${API_GET_ASSETS_FACILITY}?`
      if(assetId) {
        url+=`assetId=${assetId}&`;
      }
      if(facilityId) {
        url+=`facilityId=${facilityId}&`;
      }
      if(userId) {
        url+=`userId=${userId}`;
      }

      getApi(url, onSuccess, onFail);
    };
}

export function addAssetsAllocation(body) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_FACILITY_ASSET_LIST_INITIATE
    });
    const onFail = () => {
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);
      dispatch({
        type: ADD_FACILITY_ASSET_LIST
      });
    }
    const onSuccess = (data) => {
      NotificationManager.success(body.id ? Constants.ASSET_UPDATED : Constants.ASSET_ADDED, Constants.SUCCESS, 3000);
    dispatch(loadAssetFacilities(body.assetId));

    }
    postApi(API_ADD_ASSETS_FACILITY, onSuccess, onFail, body);

  };
};
