import { lazy } from "react";

const PeopleList = lazy(() => import("./PeopleList"));

const PeopleRoutes = [
  {
    path: "/people",
    component: PeopleList
  }
];

export default PeopleRoutes;
