import {
  SET_ASSETS,
  SET_ASSETS_DETAILS,
  SELECT_ASSETS,
  ADD_ASSSET_INITIATE,
  ADD_ASSSET_FAILURE,
  LOAD_ASSET,
  LOAD_ASSET_INITIATE,
  SAVE_CUSTOM_FIELD_INITIATE,
  SAVE_CUSTOM_FIELD_RESPONDED,
  SET_ASSETS_INVENTORY,
  PEOPLE_SET_USERS,
  ASSET_SET_FACILITY_DATA,
  SET_FACILITY_ASSET_LIST,
  ADD_FACILITY_ASSET_LIST,
  ADD_FACILITY_ASSET_LIST_INITIATE,
} from "../actions/assetsAction";

const initialState = { loading: true };

const assetsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_ASSETS_INVENTORY: {
      return {
        ...state,
        inventory: action.data,
      }
    }
    case SET_ASSETS: {
      return {
        ...state,
        assetsTypes: action.data,
        assetDetails: null,
        assetsList: null
      };
    }
    case LOAD_ASSET_INITIATE: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_CUSTOM_FIELD_INITIATE: {
      return {
        ...state,
        loadingCustomField: true
      };
    }
    case SAVE_CUSTOM_FIELD_RESPONDED: {
      return {
        ...state,
        loadingCustomField: false
      };
    }
    
    case LOAD_ASSET: {
      return {
        ...state,
        assetDetails: null,
        assetsList: action.data,
        activeTypeId: action.activeTypeId,
        loading: false
      };
    }

    case SET_ASSETS_DETAILS: {
      return {
        ...state,
        assetDetails: action.data,
        loading: false
      };
    }
    case SELECT_ASSETS: {
      return {
        ...state,
        selectedAsset: action.data,
      };
    }
    case ADD_ASSSET_INITIATE: {
      return {
        ...state,
        loading: true
      };
    }
    case ADD_ASSSET_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case PEOPLE_SET_USERS: {
      return {
        ...state,
        users: action.data
      };
    }
    case ASSET_SET_FACILITY_DATA: {
      return {
        ...state,
        facilityList: action.data,
      };
    }
    case SET_FACILITY_ASSET_LIST: {
      return {
        ...state,
        facilityAssetListList: action.data,
        loading: false
      };
    }
    case ADD_FACILITY_ASSET_LIST: {
      return {
        ...state,
        loading: false
      };
    }
    case ADD_FACILITY_ASSET_LIST_INITIATE: {
      return {
        ...state,
        loading: true
      };
    }
    
    default: {
      return state;
    }
  }
};

export default assetsReducer;
