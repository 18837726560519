import { API_GET_FACILITY, API_GET_FACILITY_TREE, API_ADD_UPDATE_FACILITES } from "app/services/APIConstants";
import { getApi, postApi} from "app/services/ApiService";
import { FACILITY_TYPE_ROOM, FACILITY_TYPE_SITES } from "app/views/assistt/modules/facility/facilityService";
import {NotificationManager} from 'react-notifications';

export const SET_FACILITY_DATA = "FACILITY_SET_DATA";
export const REMOVE_FACILITY_DATA = "FACILITY_REMOVE_DATA";
export const SET_FACILITY_DATA_ROOM = "SET_FACILITY_DATA_ROOM";
export const SET_FACILITY_SITES = "SET_FACILITY_SITES";
export const SET_GLOBAL_FACILITY = "SET_GLOBAL_FACILITY";

export function getSites() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (response) => {
      dispatch({
          type: SET_FACILITY_DATA,
          data: response.facilities
      })
    }
    const url = API_GET_FACILITY + "?facilityType=" + FACILITY_TYPE_SITES
    getApi(url, onSuccess, onFail);
  };
}

export function getGlobalSites() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (response) => {
      dispatch({
          type: SET_FACILITY_SITES,
          data: response.facilities
      })
    }
    const url = API_GET_FACILITY + "?facilityType=" + FACILITY_TYPE_SITES
    getApi(url, onSuccess, onFail);
  };
}

// function getLoadData(facilityType,data){
//   return (dispatch, getState) => {
//     const onFail = (error) => {
//      if(error?.message){
//         NotificationManager.error('',error?.message);
//       }else{
//         NotificationManager.error('','Something is wrong.Please try again.');
//       }
//       console.log("data ======> ", error)
//     }
//     const onSuccess = (response) => {
//       dispatch({
//           type: SET_FACILITY_DATA,
//           data: response.facilities
//       })
//       dispatch(drilDownFacility(data))
//     }
//     const url = API_GET_FACILITY + "?facilityType=" + facilityType
//     getApi(url, onSuccess, onFail);
//   };
// }

export function drilDownFacility(facility, doNotChangeActivePath) {
  return (dispatch, getState) => {

    const onFail = (error) => {
      console.log("data ======> ", error)
    }

    const appendChild = (facilityList, pathArray, newParent) => {

      if (pathArray.length === 1) {
        return facilityList.map(f => {
          if (f.id == pathArray[0]) {
            console.log("check-3.1",facilityList, pathArray);
            return {...f, 
              childFacilities: [...f.childFacilities.filter(f1 => f1.id != newParent.id), newParent]
            }
          } else {
            return f;
          }
        })
      } else {
        return facilityList.map(f => {
          if (f.id == pathArray[0]) {
            return {...f, 
              childFacilities: appendChild(f.childFacilities, pathArray.slice(1), newParent)
            }
          } else {
            return f;
          }
        })
      }
    }

    const getData = (facilityList, activePath, newParent) => {
      if(activePath)
        return appendChild(facilityList, activePath.toString().split(','),newParent) 
      else {
        const remainingList = facilityList.filter(f => f.id != newParent.id);
        return [...remainingList, newParent]
      } 
    }

    const onSuccess = (response) => {
      const parent = response.facilities[0] ? response.facilities[0] : facility
      let activePath = getState().facility.activePath
      if (doNotChangeActivePath === true && activePath) {
        const arr = activePath.toString().split(',')
        arr.pop()
        activePath = arr.length > 0 ? arr.join(',') : undefined
      }
               //.substring(getState().facility.activePath.toString().lastIndexOf(',')) 
      
      const data = getData(getState().facility.facilityList, activePath, parent)

      dispatch({
          type: SET_FACILITY_DATA,
          data: data,
          activePath: activePath ?  activePath +','+ parent.id: parent.id
      })
    }
    
    const url = API_GET_FACILITY_TREE + "?facilityId=" + facility.id
    getApi(url, onSuccess, onFail);
  };
}

export function backAction() {
  return (dispatch, getState) => {
    const parent = getState().facility.parent
    if (parent && parent.parentId) {
      const onFail = (error) => {
        console.log("data ======> ", error)
      }
      const onSuccess = (response) => {
        if (response.facilities[0]) {
          const parent = response.facilities[0]
          const childs = parent ? parent.childFacilities : []
          delete parent["childFacilities"]
          dispatch({
              type: SET_FACILITY_DATA,
              data: childs,
              parent: parent
          })
        }
      }
      const url = API_GET_FACILITY_TREE + "?facilityId=" + parent.parentId
      getApi(url, onSuccess, onFail);
    } else {
      dispatch(getSites())
    }
  }
}


export function addUpdateFacilites(faciliteData, currentFacility) {
  return (dispatch,getState) => {
   const onFail = (error) => {
      console.log("error",error)
      if(error && error.message){
        NotificationManager.error('', error.message);
      }else{
        NotificationManager.error('','Something is wrong.Please try again.');
      }
    }
    const onSuccess = (data) => {
      NotificationManager.success('', data.ResponseMessage);
      if(faciliteData['facilityType'] === FACILITY_TYPE_SITES){
        dispatch(getSites())
      }else{
        let newData = []
        newData['id'] = faciliteData.parentId;
        dispatch(drilDownFacility(currentFacility, true))
        
      }
    }

    postApi(API_ADD_UPDATE_FACILITES, onSuccess, onFail,faciliteData);
  };
}

export function getRooms() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error);
    };
    const onSuccess = (response) => {
      dispatch({
        type: SET_FACILITY_DATA_ROOM,
        data: response.facilities,
      });
    };
    const url = API_GET_FACILITY + "?facilityType=" + FACILITY_TYPE_ROOM;
    getApi(url, onSuccess, onFail);
  };
}

export function onGlobalSiteSelect(site) {
  return {
   type: SET_GLOBAL_FACILITY,
   site
  };
}