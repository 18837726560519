import { lazy } from "react";

const GuestPassList = lazy(() => import("./GuestPassList"));

const GuestPassRoutes = [
  {
    path: "/guestPass",
    component: GuestPassList
  }
];

export default GuestPassRoutes;
