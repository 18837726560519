import {
  API_GET_TOURS,
  API_GET_CHECKPOINTS, API_ADD_UPDATE_CHECKPOINTS, API_GET_TOUR_ROUTE, API_GET_TOURS_ACTIVITY, API_GET_PATROL_INCIDENTS, API_SAVE_TOUR,
  API_UPDATE_TOUR_SETTING, API_ADD_TOUR_STOP, API_GET_TOUR_STOP, API_GET_CHECK_POINT_FORM, API_ADD_PATROL_INCIDENT_TYPE, API_GET_CHECK_POINT_ACTIVITY, API_GET_PATROL_INCIDENT_TYPE, API_ADD_PATROL_TOUR_STOP_QUESTIONS, API_SEND_PATROL_INCIDENT_COMMENT, API_GET_PATROL_ACTIVITY_LOGS, API_GET_PATROL_STATUS_SUMMARY, API_GET_PATROL_DATE_WISE_SUMMARY, API_GET_TOUR_PATROL_STATUS_SUMMARY,
  API_PATROL_ASSIGN_TOUR, API_GET_TOUR_PATROL_DATE_WISE_SUMMARY
} from "app/services/APIConstants";
import Constants from "app/services/Constants";
import { getApi, postApi, putApi } from "app/services/ApiService";
import { NotificationManager } from "react-notifications";
import {dashboard30DaysData,
  dashboardSummaryData,
  dashboardIncindentActivity,
  dashboardTourOverview,
  dashboard30DaysTourData } from "app/views/assistt/modules/patrol/patrolService";

export const PATROL_SET_POINT_TAGS = "PATROL_SET_POINT_TAGS";
export const PATROL_SET_TOURS = "PATROL_SET_TOURS";
export const PATROL_SET_SELECTED_TOURS = "PATROL_SET_SELECTED_TOURS";
export const PATROL_SET_TOURS_ACTIVITY = "PATROL_SET_TOURS_ACTIVITY"
export const PATROL_SET_INCIDENTS = "PATROL_SET_INCIDENTS";
export const PATROL_SET_INCIDENT_DETAILS = "PATROL_SET_INCIDENT_DETAILS";
export const PATROL_CHANGE_TOUR_SETTING = "PATROL_CHANGE_TOUR_SETTING";
export const PATROL_UPDATE_TOUR_SETTING = "PATROL_UPDATE_TOUR_SETTING";
export const PATROL_SET_SELECTED_TOUR_STOPS = "PATROL_SET_SELECTED_TOUR_STOPS";
export const PATROL_SET_SELECTED_TOUR_STOPS_QUESTIONS = "PATROL_SET_SELECTED_TOUR_STOPS_QUESTIONS";
export const PATROL_SET_SELECTED_STOP = "PATROL_SET_SELECTED_STOP";
export const PATROL_DASHBOARD_SUMMARY_DATA = "PATROL_DASHBOARD_SUMMARY_DATA";
export const PATROL_DASHBOARD_30_DAYS_DATA = "PATROL_DASHBOARD_30_DAYS_DATA";
export const PATROL_DASHBOARD_ACTIVITY_LOG = "PATROL_DASHBOARD_ACTIVITY_LOG";
export const PATROL_DASHBOARD_INCIDENT_ACTIVITY =
  "PATROL_DASHBOARD_INCIDENT_ACTIVITY";
export const PATROL_DASHBOARD_TOUR_OVERVIEW_DATA =
  "PATROL_DASHBOARD_TOUR_OVERVIEW_DATA";
export const PATROL_DASHBOARD_30_DAYS_TOUR_OVERVIEW_DATA =
  "PATROL_DASHBOARD_30_DAYS_TOUR_OVERVIEW_DATA";
export const PATROL_SET_SELECTED_TOUR_ACTIVITY = "PATROL_SET_SELECTED_TOUR_ACTIVITY";
export const PATROL_SET_CHECK_POINT_ACTIVITY = "PATROL_SET_CHECK_POINT_ACTIVITY";
export const PATROL_SET_PATROL_INCIDENT_TYPE = "PATROL_SET_PATROL_INCIDENT_TYPE";
export const PATROL_SET_PATROL_TOUR = "PATROL_SET_PATROL_TOUR";


const onFail = (error) => {
  const message = error && error instanceof String ? error : Constants.ERROR_COMMON
  NotificationManager.error(message, Constants.ERROR, 3000);
};

export function loadPointTags() {
  return (dispatch, getState) => {
    const onSuccessCheckPoints = (checkpoints) => {
      const onSuccess = (tourRoutes) => {
        const finalCheckpoints = checkpoints.checkPoints.map(cp => {
          let count = tourRoutes?.routes.filter(r => r.checkPoint.id == cp.id).length
          return Object.assign(cp, { tourUsed: count })
        })

        dispatch({
          type: PATROL_SET_POINT_TAGS,
          data: finalCheckpoints,
        });
      };
      getApi(API_GET_TOUR_ROUTE, onSuccess, onFail);
    };

    getApi(API_GET_CHECKPOINTS, onSuccessCheckPoints, onFail);
  };
}

export function loadPointTagsForDropDown() {
  return (dispatch, getState) => {
    if (getState().patrol.pointTags === undefined || getState().patrol.pointTags.length === 0) {

      const onSuccessCheckPoints = (data) => {
        dispatch({
          type: PATROL_SET_POINT_TAGS,
          data: data.checkPoints ? data.checkPoints : [],
        });
      };
      getApi(API_GET_CHECKPOINTS, onSuccessCheckPoints, onFail);
    }
  };
};

export function loadTours() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: PATROL_SET_TOURS,
        data: data.tours.map(tour => Object.assign({}, tour, { "stops": tour.checkPoints.length })),
      });
    };

    getApi(API_GET_TOURS, onSuccess, onFail);
  };
}

export function addUpdatePointTags(pointagData, currentPointtag) {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("error", error);
      if (error && error.message) {
        NotificationManager.error("", error.message);
      } else {
        NotificationManager.error("", "Something is wrong.Please try again.");
      }
    };

    const onSuccess = (data) => {
      NotificationManager.success("", data.ResponseMessage);
      dispatch(loadPointTags());
    };
    console.log("PD", pointagData);

    postApi(API_ADD_UPDATE_CHECKPOINTS, onSuccess, onFail, pointagData);
  };
}

export function setSelectTour(tour) {
  return (dispatch, getState) => {
    dispatch({
      type: PATROL_SET_SELECTED_TOURS,
      data: tour,
    });
  };
}

export function loadToursActivity() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: PATROL_SET_TOURS_ACTIVITY,
        data: data.tours,
      });
    };

    getApi(API_GET_TOURS_ACTIVITY, onSuccess, onFail);
  };
}

export function loadCheckPointActivity() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: PATROL_SET_CHECK_POINT_ACTIVITY,
        data: data.checkPoints,
      });
    };
    if (getState().patrol.selectedTourActivity && getState().patrol.selectedTourActivity.id && getState().patrol.selectedTourActivity.tour.id) {
      const URL = API_GET_CHECK_POINT_ACTIVITY + "?conductedTourId=" + getState().patrol.selectedTourActivity.id + "&tourId=" + getState().patrol.selectedTourActivity.tour.id
      getApi(URL, onSuccess, onFail);
    }
  };
}

export function loadPatrolIncidentTYpes() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: PATROL_SET_PATROL_INCIDENT_TYPE,
        data: data.types,
      });
    };
    getApi(API_GET_PATROL_INCIDENT_TYPE, onSuccess, onFail);
  };
}


export function loadPatrolIncidents(params) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
        type: PATROL_SET_INCIDENTS,
        data: data.list
      })
    }
    let url = params == undefined ? API_GET_PATROL_INCIDENTS : `${API_GET_PATROL_INCIDENTS}?`;
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        const element = params[key];
        url += `${key}=${element}&`;
      }
    }
    getApi(url, onSuccess, onFail);
  };
};

export function loadPatrolIncidentDetail(incident = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: PATROL_SET_INCIDENT_DETAILS,
      data: incident
    })
  };
}

export function saveTour(tour) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      handleError(data, () => dispatch(loadTours()))
      console.log("success data", data)

    }

    postApi(API_SAVE_TOUR, onSuccess, onFail, Object.assign({}, tour, { "duration": 0, "strictToOrder": false }));
  };
}

function handleError(data, onSuccess) {
  if (data.responseCode === 0 || data.result.responseCode === 0) {
    onSuccess()
  } else {
    onFail(data.ResponseMessage)
  }
}

export function submitTourSetting(settingData) {
  return (dispatch, getState) => {

    const onSuccess = (data) => {
      handleError(data, () => dispatch({
        type: PATROL_UPDATE_TOUR_SETTING,
        settingData
      }))
    }

    postApi(API_UPDATE_TOUR_SETTING, onSuccess, onFail, Object.assign({}, settingData, { "tourId": getState().patrol?.selectedTour?.id }));
  };
}

export function saveTourStop(values) {
  return (dispatch, getState) => {

    let req = Object.assign({}, values, { "tourId": getState().patrol?.selectedTour?.id });

    const onSuccess = (data) => {
      handleError(data, () => dispatch(loadStopsForTour()))
    }

    postApi(API_ADD_TOUR_STOP, onSuccess, onFail, req);
  };
}

export function loadStopsForTour() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      console.log("test-1", data)
      handleError(data, () => dispatch({
        type: PATROL_SET_SELECTED_TOUR_STOPS,
        data: data.routes
      }))
    }
    const path = API_GET_TOUR_STOP + "?tourId=" + getState().patrol?.selectedTour?.id
    getApi(path, onSuccess, onFail);
  };
}

export function loadStopsQuestions() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      handleError(data, () => dispatch({
        type: PATROL_SET_SELECTED_TOUR_STOPS_QUESTIONS,
        data: data.checkPointForm
      }))
    }
    const path = API_GET_CHECK_POINT_FORM + "?tourId=" + getState().patrol?.selectedTour?.id
    getApi(path, onSuccess, onFail);
  };
}

export function setSelectTourStop(stop) {
  return (dispatch, getState) => {
    dispatch({
      type: PATROL_SET_SELECTED_STOP,
      data: stop,
    });
  };
}

export function loadSummaryData() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
    type: PATROL_DASHBOARD_SUMMARY_DATA,
        data: data.summary
      })
  }
    getApi(API_GET_PATROL_STATUS_SUMMARY, onSuccess, onFail);
  };
}

export function loadTourOverviewData() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
    type: PATROL_DASHBOARD_TOUR_OVERVIEW_DATA,
        data: data.summary
      })
    }
    getApi(API_GET_TOUR_PATROL_STATUS_SUMMARY + '?days=7', onSuccess, onFail);
  };
}

export function loadLast30DaysData() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
    type: PATROL_DASHBOARD_30_DAYS_DATA,
        data: data.summary
      })
  }
    getApi(API_GET_PATROL_DATE_WISE_SUMMARY, onSuccess, onFail);
  };
}


export function loadLast30TourOverviewData() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch({
    type: PATROL_DASHBOARD_30_DAYS_TOUR_OVERVIEW_DATA,
        data: data.summary
      })
  }
    getApi(API_GET_TOUR_PATROL_DATE_WISE_SUMMARY, onSuccess, onFail);
  };
}


export function loadActivityLog() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      handleError(data, () =>
        dispatch({
          type: PATROL_DASHBOARD_ACTIVITY_LOG,
          data: data.logs
        })
      );
    };

    getApi(API_GET_PATROL_ACTIVITY_LOGS, onSuccess, onFail);
  };
}

export function setSelectedTourActivity(tourActivity) {
  return {
    type: PATROL_SET_SELECTED_TOUR_ACTIVITY,
    data: tourActivity,
  }
}

export function savePatrolIncidentType(finalValues) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      handleError(data, () => dispatch(loadPatrolIncidentTYpes()))
    }

    postApi(API_ADD_PATROL_INCIDENT_TYPE, onSuccess, onFail, finalValues);
  };
}

export function saveTourStopQuestions(values) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      dispatch(loadStopsQuestions())
    }
    const finalValues =  Object.assign({}, values, {"tourId": getState().patrol.selectedTourStop.tourId, "checkPointId": getState().patrol.selectedTourStop.checkPoint.id}) 
    postApi(API_ADD_PATROL_TOUR_STOP_QUESTIONS, onSuccess, onFail, finalValues);
  };
}

export function sendPatrolIncidentMessage(body) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
     
    }
    postApi(API_SEND_PATROL_INCIDENT_COMMENT, onSuccess, onFail, body);
  };
}

export function loadIncidentActivity() {
  return {
    type: PATROL_DASHBOARD_INCIDENT_ACTIVITY,
    data: dashboardIncindentActivity,
  };
}


export function assignUserTour(data, user) {
  return (dispatch, getState) => {
    const onSuccess = (res) => {
        dispatch({
          type: PATROL_SET_PATROL_TOUR,
          data: data.tourId,
          user
        })
    }

    postApi(API_PATROL_ASSIGN_TOUR, onSuccess, onFail, data);
  };
}