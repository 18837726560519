import axios from "axios";
import localStorageService from "./localStorageService";
import { LOGIN_API } from "./APIConstants";
import {axiosInstance, setConfig} from "./ApiService";

class JwtAuthService {
  
  user = {
    userId: "1",
    role: 'ADMIN',
    displayName: "Watson Joyce",
    email: "watsonjoyce@gmail.com",
    photoURL: "/assets/images/face-7.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }
  getUserInRequireFormat = (user, token) => {
    return {
      userId: user.id,
      role: 'ADMIN',
      displayName: user.firstname,
      email: user.email,
      photoURL: user.photo,
      age: 1,
      token: token
    }
  }

  async loginWithEmailAndPassword(email, password, onSuccess, onError) {
    await axiosInstance.post(LOGIN_API, {username: email, password: password}).then((response) => {
      console.log(response, " ======= ", response.data)
      this.setSession(response.data.token);
      this.setUser({...response.data.user, token: response.data.token, role: 'ADMIN'});
      this.setCompany(response.data.company);
      setConfig(response.data.token);
      onSuccess({...response.data.user, token: response.data.token, role: 'ADMIN'}, response.data.company)
    }).catch((error)=> onError(error))
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      // setAPIHeaderToken(token)
    } else {
      localStorage.removeItem("jwt_token");
      // delete axiosInstance.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("auth_user_company");
  }
  setCompany = (company) => localStorageService.setItem("auth_user_company", company)
}

export default new JwtAuthService();
