import { lazy } from "react";

const FacilityView = lazy(() => import("./FacilityView"));

const FacilityRoutes = [
  {
    path: "/facilities",
    component: FacilityView
  }
];

export default FacilityRoutes;
