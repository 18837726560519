export const API_URL =  "http://ec2-54-90-204-72.compute-1.amazonaws.com:8080/api"; //"http://localhost:8080/api";

//==========GET APIs=========
export const API_GET_USERS = "/users";
export const API_GET_PROFILE_SETTING = "/company/profile-setting";
export const API_GET_CUSTOM_FIELD = "custom-fields";
export const API_GET_FACILITY = "facilities"
export const API_GET_FACILITY_TREE = "facilities-tree"
export const API_GET_GUEST_PASS = "guest_pass/details"
export const API_GET_RESIDENT = "facility/allocations"
export const API_GET_ASSETS_TYPE = "assets/type";
export const API_GET_ASSETS = "assets";
export const API_GET_ASSETS_DETAIL = "asset-details";
export const API_GET_ASSETS_INVENTORY = "assets/inventory";
export const API_GET_ASSETS_FACILITY = "assets/allocations";
export const API_GET_INCIDENTS = "incidents";
export const API_GET_ASSESSMENTS = "assessments";
export const API_GET_ASSESSMENT_DETAIL = "assessments-detail";
export const API_GET_INSPECTION = "inspections";
export const API_GET_TOURS = "tours";
export const API_GET_CHECKPOINTS = "check-points";
export const API_GET_TOUR_ROUTE = "tour-route";
export const API_GET_TOURS_ACTIVITY = "tour-activities"
export const API_GET_PATROL_INCIDENTS = "patrol/incidents";
export const API_GET_TOUR_STOP = "tour-route";
export const API_GET_CHECK_POINT_FORM = "check-points/form"
export const API_GET_CHECK_POINT_ACTIVITY = "check-point-activities";
export const API_GET_PATROL_INCIDENT_TYPE = "patrol/incidents/types";
export const API_GET_PATROL_ACTIVITY_LOGS = "patrol/logs";
export const SSO_LOGIN = "ssologin";
export const API_GET_VIOLATION_TYPE = "violation-types";
export const API_GET_VIOLATIONS = "violations"
export const API_GET_COMPLIANCE_ACTIVITY_LOGS = "compliance/logs"
export const API_GET_COMPLIANCED_INCIDENT = "compliance/incidents";
export const API_GET_USER_PROFILE = "user-profile";
export const API_GET_TOUR_PATROL_STATUS_SUMMARY = "patrol/tour/status-summary"
export const API_GET_TOUR_PATROL_DATE_WISE_SUMMARY = "patrol/tour/date-wise-summary"
export const API_GET_PATROL_STATUS_SUMMARY = "patrol/incident/status-summary"
export const API_GET_PATROL_DATE_WISE_SUMMARY = "patrol/incident/date-wise-summary"


//==========POST APIs=========
export const LOGIN_API = "/login";
export const API_CREATE_USER = "/users";
export const API_CREATE_ASSETS_TYPE = "assets/type";
export const API_ASSETS_CUSTOM_FIELD_ANSWERS = "assets/custom-field-answers";
export const API_ASSETS_CUSTOM_FIELD = "assets/custom-fields";
export const API_ADD_ASSETS = "assets";
export const API_CREATE_ASSETS_INVENTORY = "asset/inventory";
export const API_ADD_ASSETS_FACILITY = "assets/allocation";
export const API_ADD_UPDATE_FACILITES = "/facilities";
export const API_POST_ASSESSMENTS = "/assessments";
export const API_POST_INSPECTIONS = "/inspections";
export const API_SAVE_TOUR= "tours";
export const API_UPDATE_TOUR_SETTING = "tour-settings";
export const API_ADD_TOUR_STOP = "tour-route";
export const API_ADD_UPDATE_CHECKPOINTS = "check-points";
export const API_ADD_PATROL_INCIDENT_TYPE = "patrol/incidents/types";
export const API_ADD_PATROL_TOUR_STOP_QUESTIONS = "check-points/form";
export const API_SEND_PATROL_INCIDENT_COMMENT = "patrol/incidents/comment"
export const API_PATROL_ASSIGN_TOUR = "assign-tour"
//====PUT APIs==========
export const API_UPDATE_GUEST_PASS_STATUS = "guest_pass/status";
export const API_SEND_COMPLIANCE_INCIDENT_COMMENT = "compliance/incidents/comment"
export const API_POST_USER_PROFILE = "user-profile";
