import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import companyReducer from "./CompanyReducer";
import peopleReducer from  "./PeopleReducer";
import facilityReducer from "./FacilityReducer"
import complianceReducer from "./ComplianceReducer";
import guestPassReducer from "./GuestPassReducer"
import residentReducer from "./ResidentReducer";
import patrolReducer from "./PatrolReducer";
import assetsReducer from "./assetsReducer";
import incidentReducer from "./incidentReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  company: companyReducer,
  people: peopleReducer,
  facility: facilityReducer,
  compliance: complianceReducer,
  guestPass: guestPassReducer,
  resident: residentReducer,
  patrol: patrolReducer,
  assets: assetsReducer,
  incidents: incidentReducer
});

export default RootReducer;
