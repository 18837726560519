import { lazy } from "react";

const ResidentView = lazy(() => import("./ResidentView"));

const ResidentRoutes = [
  {
    path: "/residential",
    component: ResidentView
  }
];

export default ResidentRoutes;
