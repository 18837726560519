import {
  SET_GUEST_PASS
  } from "../actions/GuestActions";
  
  const initialState = {};
  
  const guestPassReducer = function(state = initialState, action) {
    switch (action.type) {
      case SET_GUEST_PASS: {
        return {
          ...state,
          guestPasses: action.data
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default guestPassReducer;
  