import { PATROL_INCIDENT_DETAIL_URL, PATROL_INCIDENT_URL, PATROL_POINT_TAGS_URL, PATROL_URL, TOURS_URL, TOUR_ACTIVITY_DETAIL_URL, TOUR_ACTIVITY_URL, TOUR_DETAIL_URL, PATROL_INCIDENT_TYPE_URL } from "app/services/WEB_URL";
import { lazy } from "react";

const Patrol = lazy(() => import("./Patrol"));
const PointTags = lazy(() => import("./PointTags"));
const Tours = lazy(() => import("./Tours"));
const PatrolIncidentList = lazy(() => import("./PatrolIncidentList"));
const TourDetail = lazy(() => import("./TourDetail"));
const ToursActivity = lazy(() => import("./ToursActivity"));
const PatrolIncidentDetail = lazy(() => import("./PatrolIncidentDetail"));
const TourActivityDetail = lazy(() => import("./TourActivityDetail"));
const PatrolIncidentType = lazy(() => import("./PatrolIncidentType"));

const PatrolRoutes = [
  {
    path: PATROL_POINT_TAGS_URL,
    component: PointTags
  },
  {
    path: TOURS_URL,
    component: Tours
  },
  {
    path: PATROL_INCIDENT_URL,
    component: PatrolIncidentList
  },
  {
    path: PATROL_INCIDENT_DETAIL_URL,
    component: PatrolIncidentDetail
  },
  {
    path: TOUR_DETAIL_URL,
    component: TourDetail
  },
  {
    path: TOUR_ACTIVITY_DETAIL_URL,
    component: TourActivityDetail
  },
  {
    path: TOUR_ACTIVITY_URL,
    component: ToursActivity
  },
  {
    path: PATROL_INCIDENT_TYPE_URL,
    component: PatrolIncidentType
  },
  {
    path: PATROL_URL,
    component: Patrol
  }
];

export default PatrolRoutes;
