import { API_GET_USERS, API_CREATE_USER } from "app/services/APIConstants";
import { getApi, postApi } from "app/services/ApiService";


export const PEOPLE_SET_USERS = "PEOPLE_SET_USERS"

export function loadPeople() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (data) => {
      dispatch({
        type: PEOPLE_SET_USERS,
        data: data
      })
    }
    getApi(API_GET_USERS, onSuccess, onFail, true);
  };
}

export function savePeople(body) {
  return (dispatch,getState) => {

    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (data) => {
      dispatch(loadPeople())
    }

    postApi(API_CREATE_USER, onSuccess, onFail,Object.assign({},body,{userType: "admin"}));
  };
}