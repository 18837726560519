import { lazy } from "react";

const AssetsList = lazy(() => import("./List/AssetsList"));
const AssetsTypes = lazy(() => import("./AssetsTypes"));
const AssetsInventory = lazy(() => import("./AssetsInventory"));
const AssetDetails = lazy(() => import("./Details/AssetsListDetails"));

const AssetsRoutes = [
  {
    path: "/assets/type/:inventoryId",
    component: AssetsTypes
  },
  {
    path:"/assets/:typeId",
    component:AssetsList
  },
  {
    path:"/assetsdetails",
    component:AssetDetails
  },
  {
    path: "/assets",
    component: AssetsInventory
  }

];

export default AssetsRoutes;
