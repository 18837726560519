import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import widgetsRoute from "./views/widgets/widgetsRoute";
import chartsRoute from "./views/charts/chartsRoute";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import pagesRoutes from "./views/pages/pagesRoutes";
import iconsRoutes from "./views/icons/iconsRoutes";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import peopleRoutes from "./views/assistt/modules/people/peopleRoutes";
import guestPassRoutes from "./views/assistt/modules/guest/GuestPassRoutes";
import complianceRoutes from "./views/assistt/modules/compliance/complianceRoutes";
import patrolRoutes from "./views/assistt/modules/patrol/patrolRoutes";
import assetsRoutes from "./views/assistt/modules/assets-module/assetsRoutes";
import adminPanelRoutes  from "./views/assistt/modules/admin-panel/adminPanelRoutes"
import CompanyRoutes from "./views/assistt/modules/company/CompanyRoutes";
import FacilityRoutes from "./views/assistt/modules/facility/facilityRoutes";
import residentRoutes from "./views/assistt/modules/residential/residentRoutes";
import workOrderRoutes  from "./views/assistt/modules/work-order/WorkOrderRoutes";
import incidentRoutes from "./views/assistt/modules/incident/IncidentRoutes";
import HomeRoutes from "./views/assistt/modules/home/homeRoutes";
import ProfileRoutes from "./views/assistt/modules/profile/ProfileRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...HomeRoutes,
      ...dashboardRoutes,
      ...uiKitsRoutes,
      ...formsRoutes,
      ...widgetsRoute,
      ...chartsRoute,
      ...dataTableRoute,
      ...extraKitsRoutes,
      ...pagesRoutes,
      ...iconsRoutes,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...chatRoutes,
      ...taskManagerRoutes,
      ...calendarRoutes,
      ...ecommerceRoutes,
      ...contactRoutes,
      ...peopleRoutes,
      ...guestPassRoutes,
      ...CompanyRoutes,
      ...FacilityRoutes,
      ...complianceRoutes,
      ...patrolRoutes,
      ...assetsRoutes,
      ...adminPanelRoutes,
      ...residentRoutes,
      ...workOrderRoutes,
      ...incidentRoutes,
      ...ProfileRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
