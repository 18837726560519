const firebaseConfig = {
  // apiKey: "AIzaSyAWta6I0KLl2vHVzYIKLIDZ7CzeWv6yazc",
  // authDomain: "assistt-app-v1.firebaseapp.com",
  // databaseURL: "https://assistt-app-v1-default-rtdb.firebaseio.com",
  // projectId: "assistt-app-v1",
  // storageBucket: "assistt-app-v1.appspot.com",
  // messagingSenderId: "101987983361",
  // appId: "1:101987983361:web:58e33458381a4e203a6000",
  // measurementId: "G-9R8GQ75169"
};

export default firebaseConfig;
