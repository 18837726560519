import {
  SET_INCIDENTS,
  SET_INCIDENT_DETAILS
} from "../actions/incidentAction";

const initialState = {list: []};

const incidentReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_INCIDENTS: {
      return {
        ...state,
        list: action.data
      };
    }
    case SET_INCIDENT_DETAILS: {
      return {
        ...state,
        Detail: action.data
      };
    }
    default: {
      return state;
    }
  }
};

export default incidentReducer;
