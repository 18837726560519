import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "@history.js";
import { setCompanyData, loadCompanyScreenData } from "./CompanyActions";
import { getGlobalSites, getSites } from "./FacilityActions";
import { SSO_LOGIN } from "app/services/APIConstants";
import { axiosInstance, getApi, ssoLoginDetails } from "app/services/ApiService";
import localStorageService from "app/services/localStorageService";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function loginWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    const onSuccess = (user, company) => {
      dispatch(setUserData(user));
      dispatch(setCompanyData(company));
      dispatch({
        type: LOGIN_SUCCESS
      });
      history.push({
        pathname: "/home"
      });
    }
    const onError = (error) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: error
      });
    }
    jwtAuthService.loginWithEmailAndPassword(email, password, onSuccess, onError)
      
  };
}
export function ssoLogin(authToken) {
  return dispatch => {
    const onSuccess = (data) => {
      console.log("data", data);
      jwtAuthService.setSession(authToken);
      jwtAuthService.setUser({...data.user, token: authToken, role: 'ADMIN'});
      jwtAuthService.setCompany(data.company);
      dispatch(setUserData({...data.user, token: authToken, role: 'ADMIN'}));
      dispatch(setCompanyData(data.company));
      dispatch({
        type: LOGIN_SUCCESS
      });
      setTimeout(()=> history.push({
        pathname: "/home"
      }), 1000);
    }
    const ssoConfig = {
      headers: {
          "Authorization" : "Bearer " + authToken
      }
    }
    dispatch({type: LOGIN_LOADING})
    ssoLoginDetails(ssoConfig, onSuccess, () => history.push({ pathname: "/login" }))
  }
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(user => {
        if (user) {
          dispatch(
            setUserData({
              userId: "1",
              role: "ADMIN",
              displayName: user.firstName + user.lastName,
              email: user.email,
              photoURL: "/assets/images/face-7.jpg",
              age: 25,
              token: user.token,
              ...user
            })
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}
