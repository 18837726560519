import { API_GET_INCIDENTS } from "app/services/APIConstants";
import { getApi } from "app/services/ApiService";
// import { DUMMY_INCIDENTS } from "app/views/assistt/modules/incident/IncidentService";


export const SET_INCIDENTS = "SET_INCIDENTS";
export const SET_INCIDENT_DETAILS = "SET_INCIDENT_DETAILS";

export function loadIncidents(params) {
    return (dispatch, getState) => {
        const onFail = (error) => {
            console.log("data ======> ", error)
        }
        const onSuccess = (data) => {
            dispatch({
                type: SET_INCIDENTS,
                data: data.list
            })
        }
        let url = params == undefined ? API_GET_INCIDENTS : `${API_GET_INCIDENTS}?`;
        console.log("params", params)
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                const element = params[key];
                url += `${key}=${element}&`;
            }
        }
        getApi(url, onSuccess, onFail);
    };
};

export function loadIncidentDetail(incident = {}) {
    return (dispatch, getState) => {
            dispatch({
                type: SET_INCIDENT_DETAILS,
                data: incident
            })
    };
};
    
