import { API_GET_FACILITY, API_GET_ASSESSMENTS, API_GET_INSPECTION, API_GET_COMPLIANCED_INCIDENT, API_GET_ASSESSMENT_DETAIL, API_POST_INSPECTIONS, API_GET_VIOLATION_TYPE, API_GET_VIOLATIONS, API_GET_COMPLIANCE_ACTIVITY_LOGS, API_SEND_COMPLIANCE_INCIDENT_COMMENT } from "app/services/APIConstants";
import { FACILITY_TYPE_SITES } from "app/views/assistt/modules/facility/facilityService";
import { getApi, postApi } from "app/services/ApiService";
import {
  NotificationManager
} from "react-notifications";
import Constants from 'app/services/Constants';
import { IncidentListMmockResponse } from "app/views/assistt/modules/compliance/complianceService";

export const SET_FACILITY_SITE_FOR_COMPLIANCE = "SET_FACILITY_SITES_DATA";
export const SELECT_SITE_FOR_COMPLIANCE = "SELECT_SITE_FOR_COMPLIANCE";
export const SET_ASSESSMENT_LIST = "SET_ASSESSMENT_LIST";
export const ASSESSMENT_LIST_INTIATE = "ASSESSMENT_LIST_INTIATE";
export const ASSESSMENT_LIST_FAILURE = "ASSESSMENT_LIST_FAILURE";
export const ADD_ASSESSMENT = "ADD_ASSESSMENT";
export const ADD_ASSESSMENT_INTIATE = "ADD_ASSESSMENT_INTIATE";
export const ADD_ASSESSMENT_FAILURE = "ADD_ASSESSMENT_FAILURE";
export const ASSESSMENT_SHOW_DETAIL_VIEW = "ASSESSMENT_SHOW_DETAIL_VIEW";
export const SET_ASSESSMENT_DETAIL = "SET_ASSESSMENT_DETAIL";
export const INSPECTION_LIST_INTIATE = "INSPECTION_LIST_INTIATE";
export const SET_INSPECTION_LIST = "SET_INSPECTION_LIST";
export const INSPECTION_LIST_FAILURE = "INSPECTION_LIST_FAILURE";
export const ADD_INSPECTION_FAILURE = "ADD_INSPECTION_FAILURE";
export const ADD_INSPECTION_INTIATE = "ADD_INSPECTION_INTIATE";
export const INSPECTION_SHOW_DETAIL_VIEW = "INSPECTION_SHOW_DETAIL_VIEW";
export const SET_VIOLATION_TYPE = "SET_VIOLATION_TYPE";
export const SET_VIOLATIONS = "SET_VIOLATIONS";
export const SET_SELECTED_VIOLATION_TYPE = "SET_SELECTED_VIOLATION_TYPE";
export const COMPLIANCE_DASHBOARD_ACTIVITY_LOG = "COMPLIANCE_DASHBOARD_ACTIVITY_LOG";
export const COMPLIANCE_INCIDENT_LIST_INTIATE = "COMPLIANCE_INCIDENT_LIST_INTIATE";
export const COMPLIANCE_SET_INCIDENT_LIST = "COMPLIANCE_SET_INCIDENT_LIST";
export const COMPLIANCE_INCIDENT_LIST_FAILURE = "COMPLIANCE_INCIDENT_LIST_FAILURE";
export const COMPLIANCE_SET_INCIDENT_DETAILS = "COMPLIANCE_SET_INCIDENT_DETAILS";
export const COMPLIANCE_SET_SECTION = "COMPLIANCE_SET_SECTION";

const onFail = (error) => {
  const message = error && error instanceof String ? error : Constants.ERROR_COMMON
  NotificationManager.error(message, Constants.ERROR, 3000);
};

export function loadSitesForCompliance() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (response) => {
      dispatch({
        type: SET_FACILITY_SITE_FOR_COMPLIANCE,
        data: response.facilities
      })
    }
    const url = API_GET_FACILITY + "?facilityType=" + FACILITY_TYPE_SITES
    getApi(url, onSuccess, onFail);
  };
}

export function changeSiteForCompliance(site) {
  return dispatch => {
    dispatch({
      type: SELECT_SITE_FOR_COMPLIANCE,
      selectedSite: site
    });
  };
}

export function loadAssesmentList() {
  return dispatch => {
    dispatch({ type: ASSESSMENT_LIST_INTIATE });
    const onFail = (error) => {
      console.log("data ======> ", error)
      dispatch({ type: ASSESSMENT_LIST_FAILURE });
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
    }
    const onSuccess = (response) => {
      dispatch({
        type: SET_ASSESSMENT_LIST,
        data: response.templates
      });
    }
    getApi(API_GET_ASSESSMENTS, onSuccess, onFail);
  };
};
// For assessment Requirement
export function loadAssesmentDetail(id) {
  return dispatch => {
    // dispatch({ type: ASSESSMENT_LIST_INTIATE });
    const onFail = (error) => {
      console.log("data ======> ", error)
      // dispatch({ type: ASSESSMENT_LIST_FAILURE });
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
    }
    const onSuccess = (response) => {
      delete response.result;
      dispatch({
        type: SET_ASSESSMENT_DETAIL,
        data: response
      });
    }
    getApi(`${API_GET_ASSESSMENT_DETAIL}?templateId=${id}`, onSuccess, onFail);
  };
};

export function showAssesmentDetail(selectedTemplate) {
  return dispatch => {
    dispatch({
      type: ASSESSMENT_SHOW_DETAIL_VIEW,
      data: selectedTemplate
    });
  };
}


export function addAssesmentTemplate(data) {
    return dispatch => {
      dispatch({ type: ADD_ASSESSMENT_INTIATE });
      const onFail = (error) => {
        console.log("data ======> ", error)
        dispatch({ type: ADD_ASSESSMENT_FAILURE });
        NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
      }
      const onSuccess = (response) => {
        dispatch(loadAssesmentList())
        // dispatch({
        //   type: ADD_ASSESSMENT,
        // });
      }
      postApi(API_GET_ASSESSMENTS, onSuccess, onFail, data);
    };
}

export function loadInspectionList() {
  return dispatch => {
    dispatch({ type: INSPECTION_LIST_INTIATE });
    const onFail = (error) => {
      console.log("data ======> ", error)
      dispatch({ type: ADD_INSPECTION_FAILURE });
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
    }
    const onSuccess = (response) => {
      dispatch({
        type: SET_INSPECTION_LIST,
        data: response.templates
      });
    }
    getApi(API_GET_INSPECTION, onSuccess, onFail);
  };
};

export function addInspectionTemplate(data) {
  return dispatch => {
    dispatch({ type: ADD_INSPECTION_INTIATE });
    const onFail = (error) => {
      console.log("data ======> ", error)
      dispatch({ type: ADD_INSPECTION_FAILURE });
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
    }
    const onSuccess = (response) => {
      dispatch(loadInspectionList())
      // dispatch({
      //   type: ADD_INSPECTION,
      // });
    }
    postApi(API_POST_INSPECTIONS, onSuccess, onFail, data);
  };
};

export function showInspectionDetail(selectedTemplate) {
  return dispatch => {
    dispatch({
      type: INSPECTION_SHOW_DETAIL_VIEW,
      data: selectedTemplate
    });
  };
}

export function loadViolationTypes() {
  return dispatch => {
     
    const onSuccess = (data) => {
      dispatch({
        type: SET_VIOLATION_TYPE,
        data: data.violationTypes
      });
    }
    getApi(API_GET_VIOLATION_TYPE, onSuccess, (e)=> onFail(e));
  };
}

export function loadViolations(id) {
  return dispatch => {
    const onSuccess = (data) => {
      dispatch({
        type: SET_VIOLATIONS,
        data: data.violations
      });
    }
    getApi(API_GET_VIOLATIONS, onSuccess, (e)=> onFail(e));
  };
}

export function setSelectedViolationType(type) {
  return {
    type: SET_SELECTED_VIOLATION_TYPE,
    data: type
  }
}

export function loadComplianceActivityLog() {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
      // handleError(data, () =>
        dispatch({
          type: COMPLIANCE_DASHBOARD_ACTIVITY_LOG,
          data: data.logs
        })
      // );
    };

    getApi(API_GET_COMPLIANCE_ACTIVITY_LOGS, onSuccess, onFail);
  };
}


export function loadIncidentList() {
  return dispatch => {
    dispatch({ type: COMPLIANCE_INCIDENT_LIST_INTIATE });
    const onFail = (error) => {
      console.log("data ======> ", error)
      dispatch({ type: COMPLIANCE_INCIDENT_LIST_FAILURE });
      NotificationManager.error(Constants.ERROR_COMMON, Constants.ERROR, 3000);    
    }
    const onSuccess = (response) => {
      dispatch({
        type: COMPLIANCE_SET_INCIDENT_LIST,
        data: response.list
      });
    }
    // onSuccess(IncidentListMmockResponse)
    getApi(API_GET_COMPLIANCED_INCIDENT, onSuccess, onFail);
  };
};

export function loadComplianceIncidentDetail(incident = {}) {
  return (dispatch, getState) => {
          dispatch({
              type: COMPLIANCE_SET_INCIDENT_DETAILS,
              data: incident
          })
  };
};

export function sendComplianceIncidentMessage(body) {
  return (dispatch, getState) => {
    const onSuccess = (data) => {
     
    }
    postApi(API_SEND_COMPLIANCE_INCIDENT_COMMENT, onSuccess, onFail, body);
  };
}
export function loadAssesMentSection(section) {
  return (dispatch, getState) => {
    dispatch({
      type: COMPLIANCE_SET_SECTION,
      data: section
    })
  }
}