import { lazy } from "react";

const CompanyView = lazy(() => import("./CompanyView"));

const CompanyRoutes = [
  {
    path: "/company",
    component: CompanyView
  }
];

export default CompanyRoutes;
