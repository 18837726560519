import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Home = lazy(() => import("./Home"));
const SSOLogin = lazy(() => import("./SSOLogin"));
const SSOLoginError = lazy(() => import("./SSOLoginError"));

const HomeRoutes = [
  {
    path: "/ssoerror",
    component: SSOLoginError,
    auth: authRoles.admin
  },
  {
    path: "/sso/:auth_token",
    component: SSOLogin,
    auth: authRoles.admin
  },
  {
    path: "/home",
    component: Home,
    auth: authRoles.admin
  }
];

export default HomeRoutes;
