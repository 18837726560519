import { PATROL_DASHBOARD_30_DAYS_DATA, PATROL_DASHBOARD_SUMMARY_DATA, PATROL_SET_CHECK_POINT_ACTIVITY, PATROL_SET_INCIDENTS, PATROL_SET_INCIDENT_DETAILS, PATROL_SET_PATROL_INCIDENT_TYPE, PATROL_SET_POINT_TAGS, PATROL_SET_SELECTED_STOP, PATROL_SET_SELECTED_TOURS, PATROL_SET_SELECTED_TOUR_ACTIVITY, PATROL_SET_SELECTED_TOUR_STOP, PATROL_SET_SELECTED_TOUR_STOPS, PATROL_SET_SELECTED_TOUR_STOPS_QUESTIONS, PATROL_SET_TOURS, PATROL_SET_TOURS_ACTIVITY, PATROL_SET_TOUR_ROUTE, PATROL_UPDATE_TOUR_SETTING, SET_FACILITY_DATA_ROOM,
  PATROL_DASHBOARD_ACTIVITY_LOG,
  PATROL_DASHBOARD_INCIDENT_ACTIVITY,
  PATROL_DASHBOARD_TOUR_OVERVIEW_DATA,
  PATROL_DASHBOARD_30_DAYS_TOUR_OVERVIEW_DATA,
  PATROL_SET_PATROL_TOUR
} from "../actions/PatrolActions";

const initialState = { selectedTour: {} };

const patrolReducer = function (state = initialState, action) {
  switch (action.type) {
    case PATROL_SET_POINT_TAGS: {
      return {
        ...state,
        pointTags: action.data
      };
    }
    case PATROL_SET_TOURS: {
      return {
        ...state,
        tours: action.data
      };
    }
    // case PATROL_SET_TOUR_ROUTE: {
    //   return {
    //     ...state,
    //     selectedTour: {
    //       ...state.selectedTour,
    //       routes: action.data
    //     }
    //   };
    // }
    case PATROL_SET_SELECTED_TOURS: {
      return {
        ...state,
        selectedTour: action.data
      };
    }
    case PATROL_SET_TOURS_ACTIVITY: {
      return {
        ...state,
        toursActivity: action.data
      };
    }
    case PATROL_SET_INCIDENTS: {
      return {
        ...state,
        incident: {
          ...state.incident,
          list: action.data
        }
      };
    }
    case PATROL_SET_INCIDENT_DETAILS: {
      return {
        ...state,
        incident: {
          ...state.incident,
          detail: action.data
        }
      };
    }
    case PATROL_UPDATE_TOUR_SETTING: {
      return {
        ...state,
        selectedTour: {
          ...state.selectedTour,
          settings: action.data
        }
      };
    }
    case PATROL_SET_SELECTED_TOUR_STOPS: {
      return {
        ...state,
        selectedTour: {
          ...state.selectedTour,
          checkPoints: action.data
        }
      };
    }
    case PATROL_SET_SELECTED_TOUR_STOPS_QUESTIONS: {
      return {
        ...state,
        selectedTour: {
          ...state.selectedTour,
          checkPointForms: action.data
        }
      };
    }
    case PATROL_SET_SELECTED_STOP: {
      return {
        ...state,
        selectedTourStop: action.data
      };
    }
    case PATROL_DASHBOARD_SUMMARY_DATA: {
      return {
        ...state,
        incidentSummary: action.data,
      };
    }
    case PATROL_DASHBOARD_30_DAYS_DATA: {
      return {
        ...state,
        incidentDateWiseSummary: action.data,
      };
    }
    case PATROL_DASHBOARD_TOUR_OVERVIEW_DATA: {
      return {
        ...state,
        tourOverview: action.data,
      };
    }
    case PATROL_DASHBOARD_30_DAYS_TOUR_OVERVIEW_DATA: {
      return {
        ...state,
        tourOverviewDateWise: action.data,
      };
    }
    case PATROL_DASHBOARD_ACTIVITY_LOG: {
      return {
        ...state,
        activityLogs: action.data,
      };
    }
    case PATROL_SET_SELECTED_TOUR_ACTIVITY: {
      return {
        ...state,
        selectedTourActivity: action.data
      };
    }
    case PATROL_SET_CHECK_POINT_ACTIVITY: {
      return {
        ...state,
        selectedTourActivity: {
          ...state.selectedTourActivity,
          checkPointActivities: action.data
        }
      };
    }
    case PATROL_SET_PATROL_INCIDENT_TYPE: {
      return {
        ...state,
        incidentTypes: action.data
      };
    }
    case PATROL_DASHBOARD_INCIDENT_ACTIVITY: {
      return {
        ...state,
        incidentActivity: action.data,
      };
    }
    case PATROL_SET_PATROL_TOUR: {
      const selectedTour = JSON.parse(JSON.stringify(state.selectedTour));
      if(!selectedTour.assignTo) selectedTour.assignTo = [];
      const index = selectedTour.assignTo.findIndex(e=>e.userId == action.user.userId);
      if(index === -1)
      selectedTour.assignTo.push(action.user)
      return {
        ...state,
        selectedTour,
      };
    }
    
    default: {
      return state;
    }
  }
};

export default patrolReducer;
