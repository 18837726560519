export default {
    CUSTOMFIELDS_UPDATED: 'Custom fields updated',
    SUCCESS: 'Success',
    ERROR_COMMON: 'Something went wrong! Please try again',
    ERROR: 'Error',
    ASSET_ADDED: 'Asset added successfully',
    ASSET_UPDATED: 'Asset updated successfully',
    INVALID_FILE: 'Please select a vaid file'
}

export const validationTypeList = [
    { "value": "none", "label": "None", "type": "text" },
    { "value": "is_empty", "label": "Is Empty", "type": "text" },
    { "value": "is_not_empty", "label": "Is Not Empty", "type": "text" },
    { "value": "text_contains", "label": "Text Contains", "type": "text" },
    { "value": "text_not_contain", "label": "Text Not Contains", "type": "text" },
    { "value": "text_starts_with", "label": "Text Starts With", "type": "text" },
    { "value": "text_ends_with", "label": "Text Ends With", "type": "text" },
    { "value": "text_is_exactly", "label": "Text Is Exactly", "type": "text" },
    { "value": "date_is", "label": "Date Is", "type": "date" },
    { "value": "date_is_before", "label": "Date Is Before", "type": "date" },
    { "value": "date_today", "label": "Date Is Today", "type": "date" },
    { "value": "date_before_today", "label": "Date Is Before Today", "type": "date" },
    { "value": "date_is_after", "label": "Date Is After", "type": "date" },
    { "value": "date_is_between", "label": "Date Is Between", "type": "date" },
    { "value": "greater_than", "label": "Greater than", "type": "number" },
    { "value": "greater_than_or_equal_to", "label": "Greater than Or Equals", "type": "number" },
    { "value": "less_than", "label": "Less Than", "type": "number" },
    { "value": "less_than_or_equal_to", "label": "Less Than Or Equals", "type": "number" },
    { "value": "is_equal_to", "label": "Is Equal To", "type": "number" },
    { "value": "is_not_equal_to", "label": "Is Not Equal", "type": "number" },
    { "value": "is_between", "label": "Is Between", "type": "number" },
    { "value": "is_not_between", "label": "Is Not Between", "type": "number" }
];


export const STATUS_COLOR_GREEN = "#60BD4B";
export const STATUS_COLOR_PURPLE = "#9900FF";
export const STATUS_COLOR_RED = "#FF0000";
export const STATUS_COLOR_ORANGE = "#FBB000";


export const questionTypeList = [
    { "value": "INPUT", "label": "Short Answer" },
    { "value": "CHECKBOX", "label": "CHECKBOX" },
    { "value": "RADIO", "label": "Multiple Choice" },
    { "value": "DROPDOWN", "label": "SELECT" }
]

export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_MANAGER = "MANAGER";
export const USER_ROLE_STAFF = "STAFF";
export const USER_ROLE_MODULE_FACILITY = "FACILITY";
export const USER_ROLE_MODULE_SAFETY = "PATROL";
export const USER_ROLE_MODULE_RESIDENTIAL = "RESIDENTIAL";
export const USER_ROLE_MODULE_ALL = "ALL";

export const USER_ROLE_MODULE_SELF_ADMIN_PANEL = "ADMIN";
export const USER_ROLE_MODULE_SELF_SERVE = "SELFSERVE";
export const USER_ROLE_MODULE_COMPLIANCE = "COMPLIANCE";
export const USER_ROLE_MODULE_SERVICES = "SERVICE";
export const USER_ROLE_MODULE_ASSETS = "ASSET"