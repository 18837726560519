import { getApi } from "app/services/ApiService";
import { API_GET_PROFILE_SETTING, API_GET_CUSTOM_FIELD } from "app/services/APIConstants";

export const SET_COMPANY_DATA = "COMPANY_SET_DATA";
export const REMOVE_COMPANY_DATA = "COMPANY_REMOVE_DATA";
export const COMPANY_SET_PROFILE_SETTING = "COMPANY_SET_PROFILE_SETTING";
export const COMPANY_SET_CUSTOM_PROFILE_FIELD = "COMPANY_SET_CUSTOM_PROFILE_FIELD";
// export const COMPANY_UPDATE_PROFILE_SETTING = "COMPANY_UPDATE_PROFILE_SETTING";

export function setCompanyData(company) {
  return {
      type: SET_COMPANY_DATA,
      data: company
    }
}

export function loadCompanyScreenData() {
  return (dispatch, getState) => {
    dispatch(loadProfileSettingsData());
    dispatch(loadCustomFieldData());
  };
}

export function loadCustomFieldData() {
  return (dispatch, getState) => {
    const onProfileSettingFail = (error) => {
      console.log("data ======> ", error)
    }
    const onProfileSettingSuccess = (data) => {
      dispatch({
          type: COMPANY_SET_CUSTOM_PROFILE_FIELD,
          data: data
      })
    }
    getApi(API_GET_CUSTOM_FIELD, onProfileSettingSuccess, onProfileSettingFail);
  };
}

export function loadProfileSettingsData() {
  return (dispatch, getState) => {
    const onProfileSettingFail = (error) => {
      console.log("data ======> ", error)
    }
    const onProfileSettingSuccess = (data) => {
      dispatch({
          type: COMPANY_SET_PROFILE_SETTING,
          data: data
      })
    }
    const PROFILE_SETTING = API_GET_PROFILE_SETTING + "/" +getState().company.domain
    getApi(PROFILE_SETTING, onProfileSettingSuccess, onProfileSettingFail);
  };
}

export function updateUserProfileSettings(field) {
  return (dispatch, getState) => {
    const ps = getState().company.profileSettings
    
    dispatch({type: COMPANY_SET_PROFILE_SETTING,
      data: Object.assign({},ps,{[field]: !ps[field]})
    })
  }
}

