import {
    SET_FACILITY_DATA, SET_FACILITY_DATA_ROOM, SET_FACILITY_SITES, SET_GLOBAL_FACILITY
  } from "../actions/FacilityActions";
  
  const initialState = {};
  
  const facilityReducer = function(state = initialState, action) {
    switch (action.type) {
      case SET_FACILITY_DATA: {
        return {
          ...state,
          facilityList: action.data,
          activePath: action.activePath,
          globalFacility: state.globalFacility ? state.globalFacility : action.data[0]
        };
      }
      case SET_FACILITY_DATA_ROOM: {
        return {
          ...state,
          facilityRooms: action.data,
        };
      }
      case SET_FACILITY_SITES: {
        return {
          ...state,
          sites: action.data,
          globalSite: state.globalSite ? state.globalSite : action.data[0]
        };
      }
      case SET_GLOBAL_FACILITY: {
        return {
          ...state,
          globalSite: action.site
        }
      }
      default: {
        return state;
      }
    }
  };
  
  export default facilityReducer;