import { lazy } from "react";
const IncidentDetail = lazy(() => import("./IncidentDetail"));

const IncidentList = lazy(() => import("./IncidentList"));

const IncidentRoutes = [
  {
    path: "/incidents",
    component: IncidentList
  },
  {
    path: "/incidentDetails",
    component: IncidentDetail
  },
];

export default IncidentRoutes;
