export const snapShotList = [
    {
        icon: "i-Check",
        title: "27/30",
        subtitle: "Completed Assessments",
        description: "90% of schedule assessment has been completed"
    },
    {
        icon: "i-Danger",
        title: "12",
        subtitle: "Violations found",
        description: "20% less violation then previous month"
    },
    {
        icon: "i-Check",
        title: "8",
        subtitle: "Violation Corrected",
        description: "60% of found violation has been corrected"
    },
    {
        icon: "i-Sand-watch-2",
        title: "2h 18m",
        subtitle: "Average Completion Time",
        description: "20% faster than previous month"
    },
    {
        icon: "i-Sand-watch-2",
        title: "2d 6h",
        subtitle: "Average Follow Up Time",
        description: "More than projected follow up time"
    },
    {
        icon: "i-Sand-watch-2",
        title: "5d 21h",
        subtitle: "Average Correction Time",
        description: "10% higher than projected correction time"
    }
];

export const assessmentList = [
    {
        name: "Physical Security Audit",
        assignedTo: [{
            firstName: "arpan",
            lastName: "shah",
            photo: ""
        },
        {
            firstName: "Punit",
            lastName: "shah",
            photo: ""
        }],
        period: "Every Month",
        followUpTime: "48 Hours",
        instructions: "The Following items will be assessed monthly. The purpose of this assessment is to confirm that procedures and physical security items are in good working order as required in the State Safety & Security Manual, BURSAR Office Guidelines, and by State Risk Management. Detailed comments should be noted if a question receives a negative response. The Risk & Compliance specialist completing this assessment must fill out a work order to have any physical security device repaired as soon as possible. A copy of this assessment will be sent to the Director of Operations, Director of Compliance, and Field Compliance Administrator.",
        email: {
            to: ["ashah@assisttsolutions.com", "pshah@assisttsolutions.com"],
            cc: ["ajlan@assisttsolutions.com"],
            subject: "Compliance update"
        }
    },
    {
        name: "Warehouse Safety Audit",
        assignedTo: [{
            firstName: "arpan",
            lastName: "shah",
            photo: ""
        }],
        period: "Every Week",
        followUPTime: "48 Hours"
    },
    {
        name: "Emergancy Response Assessment",
        assignedTo: [{
            firstName: "arpan",
            lastName: "shah",
            photo: ""
        }],
        period: "Every Month",
        followUPTime: "48 Hours"
    },
    {
        name: "Readiness Checklist",
        assignedTo: [{
            firstName: "arpan",
            lastName: "shah",
            photo: ""
        }],
        period: "Every Month",
        followUPTime: "48 Hours",

    }
];

export const IncidentListMmockResponse = {
    "result": {
      "responseCode": 0,
      "ResponseMessage": "SUCCESS"
    },
    "list": [
      {
        "id": 1,
        "facility": {
          "id": 1,
          "companyId": 1,
          "name": "MIT University Hostels",
          "location": "USA",
          "facilityType": "SITE",
          "address": "New York",
          "longitude": "28.98674",
          "latitude": "21.564849",
          "parentId": "",
          "comment": "This is MIT University data",
          "responsiblePersonId": 1
        },
        "involvedPerson": "arpan shah, punit shah, Jacky Chain, UNKNOWN",
        "requestBy": {
          "userId": 1,
          "username": "admin",
          "firstName": "ashah",
          "lastName": "shah",
          "email": "ashah@assisttsolutions.com",
          "mobile": "9999999999",
          "title": "",
          "authorities": {
            "ALL": "Admin"
          },
          "userType": "Admin",
          "gender": "male"
        },
        "summary": "test incident ",
        "description": "Major fire definition: A major fire incident is defined as an incident involving smoke, heat, and. flames causing property damage to multiple building fixtures or fittings. A major fire incident is defined as an incident involving smoke, heat, and. flames causing property damage to multiple building fixtures or fittings.",
        "requestedDate": "2022-02-02 09:00:25",
        "priority": "MEDIUM",
        "status": "NEW",
        "incidentType": {
          "id": 1,
          "typeName": "TracePassing1",
          "autoAssign": [
            {
              "userId": 5,
              "username": "user1",
              "firstName": "f_name",
              "lastName": "l_name",
              "email": "user1@assisttsolutions.com",
              "mobile": "9898989891",
              "title": "Student",
              "department": "Engineering",
              "address": "home address",
              "authorities": {
                "ASSETS": "Manager",
                "FACILITY": "Staff",
                "RESIDENT": "USER"
              },
              "userType": "Admin",
              "gender": "female",
              "mobileCountryCode": "91"
            },
            {
              "userId": 7,
              "username": "user3",
              "firstName": "f_name",
              "lastName": "l_name",
              "email": "user3@assisttsolutions.com",
              "mobile": "9898989893",
              "title": "Student",
              "department": "Engineering",
              "address": "home address",
              "authorities": {
                "ASSETS": "USER",
                "COMPLIANCE": "Staff",
                "PATROL": "Manager"
              },
              "userType": "Admin",
              "gender": "female",
              "mobileCountryCode": "91"
            },
            {
              "userId": 4,
              "username": "bmammedov",
              "firstName": "bmammedov",
              "lastName": "",
              "email": "bmammedov@assisttsolutions.com",
              "mobile": "9980998899",
              "title": "Jr. Developer",
              "department": "Mobile Tech",
              "address": "Gurukul, Ahmedabad",
              "authorities": {
                "User": "All"
              },
              "userType": "Admin",
              "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
              "gender": "male"
            }
          ],
          "defaultPriority": "MEDIUM"
        },
        "label": [
          "label1",
          "label2"
        ],
        "dueDate": "2022-02-02 14:00:25",
        "tagId": "34566549",
        "assignTo": [
          {
            "userId": 1,
            "username": "admin",
            "firstName": "ashah",
            "lastName": "shah",
            "email": "ashah@assisttsolutions.com",
            "mobile": "9999999999",
            "title": "",
            "authorities": {
              "ALL": "Admin"
            },
            "userType": "Admin",
            "gender": "male"
          },
          {
            "userId": 2,
            "username": "pshah",
            "firstName": "Punnet",
            "lastName": "Shah",
            "email": "pshah@assisttsolutions.com",
            "mobile": "9988998899",
            "title": "Lead Developer",
            "department": "Mobile Tech",
            "address": "Gurukul, Ahmedabad",
            "authorities": {
              "ASSETS": "User",
              "COMPLIANCE": "Manager"
            },
            "userType": "Admin",
            "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
            "gender": "male"
          },
          {
            "userId": 4,
            "username": "bmammedov",
            "firstName": "bmammedov",
            "lastName": "",
            "email": "bmammedov@assisttsolutions.com",
            "mobile": "9980998899",
            "title": "Jr. Developer",
            "department": "Mobile Tech",
            "address": "Gurukul, Ahmedabad",
            "authorities": {
              "User": "All"
            },
            "userType": "Admin",
            "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
            "gender": "male"
          },
          {
            "userId": 5,
            "username": "user1",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user1@assisttsolutions.com",
            "mobile": "9898989891",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "ASSETS": "Manager",
              "FACILITY": "Staff",
              "RESIDENT": "USER"
            },
            "userType": "Admin",
            "gender": "female",
            "mobileCountryCode": "91"
          },
          {
            "userId": 6,
            "username": "user2",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user2@assisttsolutions.com",
            "mobile": "9898989892",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "ASSETS": "USER",
              "COMPLIANCE": "Manager",
              "PATROL": "Staff"
            },
            "userType": "Admin",
            "gender": "female",
            "mobileCountryCode": "91"
          },
          {
            "userId": 7,
            "username": "user3",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user3@assisttsolutions.com",
            "mobile": "9898989893",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "ASSETS": "USER",
              "COMPLIANCE": "Staff",
              "PATROL": "Manager"
            },
            "userType": "Admin",
            "gender": "female",
            "mobileCountryCode": "91"
          },
          {
            "userId": 8,
            "username": "user4",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user4@assisttsolutions.com",
            "mobile": "9898989894",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "User": "All"
            },
            "userType": "Admin",
            "gender": "female",
            "mobileCountryCode": "91"
          },
          {
            "userId": 9,
            "username": "user5",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user5@assisttsolutions.com",
            "mobile": "9898989895",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "ASSETS": "Staff"
            },
            "userType": "Admin",
            "gender": "male",
            "mobileCountryCode": "91"
          },
          {
            "userId": 10,
            "username": "a",
            "firstName": "a",
            "lastName": "a",
            "email": "aaaa@aaa.cc",
            "mobile": "9988989889",
            "title": "s",
            "department": "s",
            "address": "s",
            "authorities": {
              
            },
            "userType": "Admin",
            "gender": "male"
          }
        ],
        "media": [
          {
            "id": 1,
            "incidentId": 1,
            "mediaType": "IMAGE",
            "url": "https://assistt.s3.us-west-1.amazonaws.com/assets/fire_extingusher_2.jpeg"
          },
          {
            "id": 2,
            "incidentId": 1,
            "mediaType": "IMAGE",
            "url": "https://assistt.s3.us-west-1.amazonaws.com/assets/fire_extingusher_2.jpeg"
          }
        ],
        "comments": [
          {
            "id": 2,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shah Due By 2022-01-30 22:21:28.159",
            "CommentTime": "2022-01-30 17:21:28"
          },
          {
            "id": 101,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "testing from postman",
            "CommentTime": "2022-02-14 17:08:43"
          },
          {
            "id": 102,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "testing from postman again",
            "CommentTime": "2022-02-14 17:09:50"
          },
          {
            "id": 103,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Great work",
            "CommentTime": "2022-02-14 17:55:53"
          },
          {
            "id": 104,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "test from mobile",
            "CommentTime": "2022-02-14 17:56:48"
          },
          {
            "id": 105,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "tedf",
            "CommentTime": "2022-02-14 18:06:04"
          },
          {
            "id": 106,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 09:00:25.0",
            "CommentTime": "2022-02-15 17:42:02"
          },
          {
            "id": 107,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 09:00:25.0",
            "CommentTime": "2022-02-15 17:58:33"
          },
          {
            "id": 108,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 10:00:25.0",
            "CommentTime": "2022-02-15 18:02:09"
          },
          {
            "id": 109,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2021-02-27 00:00:00.0",
            "CommentTime": "2022-02-15 18:07:35"
          },
          {
            "id": 110,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2021-02-27 00:00:00.0",
            "CommentTime": "2022-02-15 18:11:26"
          },
          {
            "id": 111,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2021-02-27 00:00:00.0",
            "CommentTime": "2022-02-15 18:14:46"
          },
          {
            "id": 112,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-26 00:00:00.0",
            "CommentTime": "2022-02-15 18:16:53"
          },
          {
            "id": 113,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-15 00:00:00.0",
            "CommentTime": "2022-02-15 18:40:47"
          },
          {
            "id": 114,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-15 00:00:00.0",
            "CommentTime": "2022-02-15 18:43:28"
          },
          {
            "id": 115,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "arpan",
            "CommentTime": "2022-02-23 16:32:43"
          },
          {
            "id": 116,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "s",
            "CommentTime": "2022-02-23 16:36:08"
          },
          {
            "id": 117,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-15 00:00:00.0",
            "CommentTime": "2022-02-28 16:37:50"
          },
          {
            "id": 118,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-15 00:00:00.0",
            "CommentTime": "2022-02-28 16:37:57"
          },
          {
            "id": 119,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-28 00:00:00.0",
            "CommentTime": "2022-02-28 16:38:08"
          },
          {
            "id": 120,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:19"
          },
          {
            "id": 121,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:20"
          },
          {
            "id": 122,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:21"
          },
          {
            "id": 123,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:21"
          },
          {
            "id": 124,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:21"
          },
          {
            "id": 125,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:21"
          },
          {
            "id": 126,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-02-28 16:38:21"
          },
          {
            "id": 127,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "testing ios",
            "CommentTime": "2022-02-28 16:38:28"
          },
          {
            "id": 128,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "testing android",
            "CommentTime": "2022-02-28 16:38:41"
          },
          {
            "id": 136,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-03-03 18:22:07"
          },
          {
            "id": 137,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-03-05 05:27:08"
          },
          {
            "id": 138,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-03-07 18:19:59"
          },
          {
            "id": 139,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-03-07 18:20:11"
          },
          {
            "id": 142,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-03-19 17:59:19"
          },
          {
            "id": 147,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-04-14 16:21:39"
          },
          {
            "id": 148,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-04-14 16:22:04"
          },
          {
            "id": 153,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-04-17 18:13:40"
          },
          {
            "id": 154,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-04-17 18:18:11"
          },
          {
            "id": 155,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "test",
            "CommentTime": "2022-04-17 18:19:50"
          },
          {
            "id": 156,
            "incidentId": 1,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-02 14:00:25.0",
            "CommentTime": "2022-04-17 18:20:15"
          }
        ]
      },
      {
        "id": 100,
        "facility": {
          "id": 1,
          "companyId": 1,
          "name": "MIT University Hostels",
          "location": "USA",
          "facilityType": "SITE",
          "address": "New York",
          "longitude": "28.98674",
          "latitude": "21.564849",
          "parentId": "",
          "comment": "This is MIT University data",
          "responsiblePersonId": 1
        },
        "involvedPerson": "f_name l_name,a a,Punnet Shah",
        "requestBy": {
          "userId": 1,
          "username": "admin",
          "firstName": "ashah",
          "lastName": "shah",
          "email": "ashah@assisttsolutions.com",
          "mobile": "9999999999",
          "title": "",
          "authorities": {
            "ALL": "Admin"
          },
          "userType": "Admin",
          "gender": "male"
        },
        "summary": "test",
        "description": "fgg",
        "requestedDate": "2022-02-12 15:28:28",
        "priority": "LOW",
        "status": "NEW",
        "incidentType": {
          "id": 1,
          "typeName": "TracePassing1",
          "autoAssign": [
            {
              "userId": 5,
              "username": "user1",
              "firstName": "f_name",
              "lastName": "l_name",
              "email": "user1@assisttsolutions.com",
              "mobile": "9898989891",
              "title": "Student",
              "department": "Engineering",
              "address": "home address",
              "authorities": {
                "ASSETS": "Manager",
                "FACILITY": "Staff",
                "RESIDENT": "USER"
              },
              "userType": "Admin",
              "gender": "female",
              "mobileCountryCode": "91"
            },
            {
              "userId": 7,
              "username": "user3",
              "firstName": "f_name",
              "lastName": "l_name",
              "email": "user3@assisttsolutions.com",
              "mobile": "9898989893",
              "title": "Student",
              "department": "Engineering",
              "address": "home address",
              "authorities": {
                "ASSETS": "USER",
                "COMPLIANCE": "Staff",
                "PATROL": "Manager"
              },
              "userType": "Admin",
              "gender": "female",
              "mobileCountryCode": "91"
            },
            {
              "userId": 4,
              "username": "bmammedov",
              "firstName": "bmammedov",
              "lastName": "",
              "email": "bmammedov@assisttsolutions.com",
              "mobile": "9980998899",
              "title": "Jr. Developer",
              "department": "Mobile Tech",
              "address": "Gurukul, Ahmedabad",
              "authorities": {
                "User": "All"
              },
              "userType": "Admin",
              "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
              "gender": "male"
            }
          ],
          "defaultPriority": "MEDIUM"
        },
        "label": [
          ""
        ],
        "dueDate": "2022-02-12 22:28:28",
        "tagId": "220212152827714950",
        "assignTo": [
          {
            "userId": 1,
            "username": "admin",
            "firstName": "ashah",
            "lastName": "shah",
            "email": "ashah@assisttsolutions.com",
            "mobile": "9999999999",
            "title": "",
            "authorities": {
              "ALL": "Admin"
            },
            "userType": "Admin",
            "gender": "male"
          },
          {
            "userId": 2,
            "username": "pshah",
            "firstName": "Punnet",
            "lastName": "Shah",
            "email": "pshah@assisttsolutions.com",
            "mobile": "9988998899",
            "title": "Lead Developer",
            "department": "Mobile Tech",
            "address": "Gurukul, Ahmedabad",
            "authorities": {
              "ASSETS": "User",
              "COMPLIANCE": "Manager"
            },
            "userType": "Admin",
            "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
            "gender": "male"
          },
          {
            "userId": 3,
            "username": "ajlan",
            "firstName": "Ajlan",
            "lastName": "Okman",
            "email": "ajlan@assisttsolutions.com",
            "mobile": "8899889989",
            "title": "Tech Head",
            "department": "Management",
            "address": "New York",
            "authorities": {
              "ALL": "Admin"
            },
            "userType": "Admin",
            "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
            "gender": "male"
          },
          {
            "userId": 8,
            "username": "user4",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user4@assisttsolutions.com",
            "mobile": "9898989894",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "User": "All"
            },
            "userType": "Admin",
            "gender": "female",
            "mobileCountryCode": "91"
          }
        ],
        "media": [
          
        ],
        "comments": [
          {
            "id": 100,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-12 16:28:27.703",
            "CommentTime": "2022-02-12 15:28:28"
          },
          {
            "id": 140,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-12 16:28:28.0",
            "CommentTime": "2022-03-19 17:57:26"
          },
          {
            "id": 141,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-03-19 00:00:00.0",
            "CommentTime": "2022-03-19 17:58:06"
          },
          {
            "id": 143,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-03-19 00:00:00.0",
            "CommentTime": "2022-03-19 17:59:47"
          },
          {
            "id": 144,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-03-19 00:00:00.0",
            "CommentTime": "2022-03-19 18:01:12"
          },
          {
            "id": 145,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-03-20 00:00:00.0",
            "CommentTime": "2022-03-20 11:11:16"
          },
          {
            "id": 146,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-03-20 00:00:00.0",
            "CommentTime": "2022-03-20 11:13:14"
          },
          {
            "id": 149,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-12 22:28:28.0",
            "CommentTime": "2022-04-14 16:30:05"
          },
          {
            "id": 152,
            "incidentId": 100,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-12 22:28:28.0",
            "CommentTime": "2022-04-15 20:27:59"
          }
        ]
      },
      {
        "id": 101,
        "facility": {
          "id": 1,
          "companyId": 1,
          "name": "MIT University Hostels",
          "location": "USA",
          "facilityType": "SITE",
          "address": "New York",
          "longitude": "28.98674",
          "latitude": "21.564849",
          "parentId": "",
          "comment": "This is MIT University data",
          "responsiblePersonId": 1
        },
        "involvedPerson": "f_name l_name",
        "requestBy": {
          "userId": 1,
          "username": "admin",
          "firstName": "ashah",
          "lastName": "shah",
          "email": "ashah@assisttsolutions.com",
          "mobile": "9999999999",
          "title": "",
          "authorities": {
            "ALL": "Admin"
          },
          "userType": "Admin",
          "gender": "male"
        },
        "summary": "Test 1",
        "description": "Test Description 1",
        "requestedDate": "2022-02-28 17:08:57",
        "priority": "HIGH",
        "status": "OPEN",
        "incidentType": {
          "id": 100,
          "typeName": "New Tour Stop Test",
          "autoAssign": [
            {
              "userId": 9,
              "username": "user5",
              "firstName": "f_name",
              "lastName": "l_name",
              "email": "user5@assisttsolutions.com",
              "mobile": "9898989895",
              "title": "Student",
              "department": "Engineering",
              "address": "home address",
              "authorities": {
                "ASSETS": "Staff"
              },
              "userType": "Admin",
              "gender": "male",
              "mobileCountryCode": "91"
            },
            {
              "userId": 4,
              "username": "bmammedov",
              "firstName": "bmammedov",
              "lastName": "",
              "email": "bmammedov@assisttsolutions.com",
              "mobile": "9980998899",
              "title": "Jr. Developer",
              "department": "Mobile Tech",
              "address": "Gurukul, Ahmedabad",
              "authorities": {
                "User": "All"
              },
              "userType": "Admin",
              "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
              "gender": "male"
            }
          ],
          "defaultPriority": "MEDIUM"
        },
        "label": [
          ""
        ],
        "dueDate": "2022-02-28 18:08:57",
        "tagId": "220228170856798767",
        "assignTo": [
          {
            "userId": 4,
            "username": "bmammedov",
            "firstName": "bmammedov",
            "lastName": "",
            "email": "bmammedov@assisttsolutions.com",
            "mobile": "9980998899",
            "title": "Jr. Developer",
            "department": "Mobile Tech",
            "address": "Gurukul, Ahmedabad",
            "authorities": {
              "User": "All"
            },
            "userType": "Admin",
            "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
            "gender": "male"
          },
          {
            "userId": 9,
            "username": "user5",
            "firstName": "f_name",
            "lastName": "l_name",
            "email": "user5@assisttsolutions.com",
            "mobile": "9898989895",
            "title": "Student",
            "department": "Engineering",
            "address": "home address",
            "authorities": {
              "ASSETS": "Staff"
            },
            "userType": "Admin",
            "gender": "male",
            "mobileCountryCode": "91"
          }
        ],
        "media": [
          
        ],
        "comments": [
          {
            "id": 129,
            "incidentId": 101,
            "user": {
              "userId": 1,
              "username": "admin",
              "firstName": "ashah",
              "lastName": "shah",
              "email": "ashah@assisttsolutions.com",
              "mobile": "9999999999",
              "title": "",
              "authorities": {
                "ALL": "Admin"
              },
              "userType": "Admin",
              "gender": "male"
            },
            "comment": "Created by ashah shahDue By 2022-02-28 18:08:56.788",
            "CommentTime": "2022-02-28 17:08:57"
          }
        ]
      }
    ]
  }

  export const INSPECTION  = {
    location: [
        {
            building: 'Robert F. Kennedy Hall',
            room:'105',
            roomDetail:'Beedroom'
        },
        {
            building: 'Robert F. Kennedy Hall',
            room:'106',
            roomDetail:'Beedroom'
        },
        {
            building: 'Robert F. Kennedy Hall',
            room:'107',
            roomDetail:'Beedroom'
        },
        {
            building: 'Robert F. Kennedy Hall',
            room:'108',
            roomDetail:'Beedroom'
        }
    ],
    assignments: [
        {
            occurrencePeriod:'Weekely',
            assignedTo :   [{
                "userId": 1,
                "username": "admin",
                "firstName": "ashah",
                "lastName": "shah",
                "email": "ashah@assisttsolutions.com",
                "mobile": "9999999999",
                "title": "",
                "authorities": {
                  "ALL": "Admin"
                },
                "userType": "Admin",
                "gender": "male"
              }]
        }
    ],
    distributionList:[
        {
            send:'TO',
            recipient:{
                "userId": 1,
                "username": "admin",
                "firstName": "ashah",
                "lastName": "shah",
                "email": "ashah@assisttsolutions.com",
                "mobile": "9999999999",
                "title": "",
                "authorities": {
                  "ALL": "Admin"
                },
                "userType": "Admin",
                "gender": "male"
              },
              titleRole:'Director of Operation',
              email:'abc@dummy.com'
        },
        {
            send:'CC',
            recipient:{
                "userId": 1,
                "username": "admin",
                "firstName": "ashah",
                "lastName": "shah",
                "email": "ashah@assisttsolutions.com",
                "mobile": "9999999999",
                "title": "",
                "authorities": {
                  "ALL": "Admin"
                },
                "userType": "Admin",
                "gender": "male"
              },
              titleRole:'Long Island Faculty Manager',
              email:'abc1@dummy.com'
        },
        {
            send:'BCC',
            recipient:{
                "userId": 1,
                "username": "admin",
                "firstName": "ashah",
                "lastName": "shah",
                "email": "ashah@assisttsolutions.com",
                "mobile": "9999999999",
                "title": "",
                "authorities": {
                  "ALL": "Admin"
                },
                "userType": "Admin",
                "gender": "male"
              },
              titleRole:'N/A',
              email:'abc2@dummy.com'
        }
    ]
};

export const LOTOINVENTORY = [
  {
    "id": 104,
    "facility": {
      "id": 2,
      "companyId": 1,
      "name": "Boys Hostel-1",
      "location": "USA",
      "facilityType": "BUILDING",
      "address": "New York",
      "longitude": "28.98674",
      "latitude": "21.564849",
      "parentId": "1",
      "comment": "hostel-1",
      "responsiblePersonId": 2
    },
    "involvedPerson": "arpan,puneet",
    "requestBy": {
      "userId": 1,
      "username": "admin",
      "firstName": "ashah",
      "lastName": "shah",
      "email": "ashah@assisttsolutions.com",
      "mobile": "9999999999",
      "title": "",
      "authorities": {
        "ALL": "Admin"
      },
      "userType": "Admin",
      "gender": "male"
    },
    "summary": "test",
    "description": "djjd",
    "requestedDate": "2022-04-20 03:45:16",
    "priority": "HIGH",
    "status": "ASSIGNED",
    "violationType": {
      "id": 1,
      "name": "Fire Safety",
      "description": "fire safety violation type",
      "autoAssign": [
        {
          "userId": 1,
          "username": "admin",
          "firstName": "ashah",
          "lastName": "shah",
          "email": "ashah@assisttsolutions.com",
          "mobile": "9999999999",
          "title": "",
          "authorities": {
            "ALL": "Admin"
          },
          "userType": "Admin",
          "gender": "male"
        },
        {
          "userId": 2,
          "username": "pshah",
          "firstName": "Punnet",
          "lastName": "Shah",
          "email": "pshah@assisttsolutions.com",
          "mobile": "9988998899",
          "title": "Lead Developer",
          "department": "Mobile Tech",
          "address": "Gurukul, Ahmedabad",
          "authorities": {
            "ASSETS": "User",
            "COMPLIANCE": "Manager"
          },
          "userType": "Admin",
          "photo": "https://assistt.s3.us-west-1.amazonaws.com/profile/default.jpeg",
          "gender": "male"
        }
      ],
      "defaultPriority": "LOW",
      "slaHigh": 10,
      "slaMedium": 20,
      "slaLow": 30,
      "abbreviation": "FS"
    },
    "violation": {
      "id": 1,
      "violationTypeId": 1,
      "name": "Fire safety bucket not working",
      "description": "detail description",
      "allowConfiscate": true,
      "allowFixIt": true,
      "allowFollowUp": true,
      "confiscateInstruction": "Confiscated Instruction will come here",
      "fixItInstruction": "Fix It Instructions",
      "followUpInstruction": "Follow Up Instruction"
    },
    "dueDate": "2022-04-20 04:45:16"
  }
]