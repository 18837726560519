import { API_GET_RESIDENT } from "app/services/APIConstants";
import { getApi } from "app/services/ApiService";

export const SET_RESIDENT_DATA = "RESIDENT_SET_DATA";

export function loadResident() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (response) => {
      dispatch({
          type: SET_RESIDENT_DATA,
          data: response.facilityAllocationDetails
      })
    }

    getApi(API_GET_RESIDENT, onSuccess, onFail);
  };
}