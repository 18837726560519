import { COMPLIANCE_INCIDENT_URL, COMPLIANCE_VIOLATION_TYPES_URL, VIOLATION_TYPE_DETAIL_URL, COMPLIANCE_INCIDENT_DETAIL_URL, COMPLIANCE_ASSESSMENT_TEMPLATE_INFO, COMPLIANCE_ASSESSMENT_SECTIONS, COMPLIANCE_ASSESSMENT_ACTIVITY, COMPLIANCE_ASSESSMENT_ACTIVITY_DETAILS, COMPLIANCE_LOTO_INVENTORY } from "app/services/WEB_URL";
import { lazy } from "react";

const ComplianceDashboard = lazy(() => import("./ComplianceDashboard"));
const AssessmentTemplateList = lazy(() => import("./Assesment/AssessmentTemplateList"));
const AssessmentDetail = lazy(() => import("./AssessmentDetail"));
const InspectionDetail = lazy(() => import("./Inspection/InspectionDetail"));
const InspectionList = lazy(() => import("./Inspection/InspectionList"));
const ViolationTypes = lazy(() => import("./ViolationTypes"));
const Violations = lazy(() => import("./Violations"));
const ComplienceIncidentList = lazy(() => import("./ComplienceIncidentList"));
const ComplianceIncidentDetail = lazy(() => import("./ComplianceIncidentDetail"));
const AssessmentTempInfo = lazy(() => import("./Assesment/AssessmentTempInfo"));
const AssessmentSectionQuestions = lazy(() => import("./Assesment/AssessmentSectionQuestions"));
const AssesmentActivity = lazy(() => import("./AssesmentActivity/AssesmentActivity"));
const AssesmentActivityDetail = lazy(() => import("./AssesmentActivity/AssesmentActivityDetail"));
const LotoInventory = lazy(() => import("./LotoInventory/LotoInventory"));

const ComplianceRoutes = [
  {
    path: COMPLIANCE_ASSESSMENT_TEMPLATE_INFO,
    component: AssessmentTempInfo
  },
  {
    path: COMPLIANCE_ASSESSMENT_SECTIONS,
    component: AssessmentSectionQuestions
  },
  {
    path: COMPLIANCE_ASSESSMENT_ACTIVITY_DETAILS,
    component: AssesmentActivityDetail
  },
  {
    path: COMPLIANCE_ASSESSMENT_ACTIVITY,
    component: AssesmentActivity
  },
  {
    path: "/compliance/assessments",
    component: AssessmentTemplateList
  },
  {
    path: "/compliance/assessment/detail",
    component: AssessmentDetail
  },
  {
    path: "/compliance/create",
    component: AssessmentTemplateList
  },
  {
    path: "/compliance/inspections",
    component: InspectionList
  },
  {
    path: "/compliance/inspection/detail",
    component: InspectionDetail
  },
  {
    path: COMPLIANCE_VIOLATION_TYPES_URL,
    component: ViolationTypes
  },
  {
    path: VIOLATION_TYPE_DETAIL_URL,
    component: Violations
  },
  {
    path: COMPLIANCE_INCIDENT_URL,
    component: ComplienceIncidentList
  },
  {
    path: COMPLIANCE_INCIDENT_DETAIL_URL,
    component: ComplianceIncidentDetail
  },
  {
    path: COMPLIANCE_LOTO_INVENTORY,
    component: LotoInventory
  },
  {
    path: "/compliance",
    component: ComplianceDashboard
  },
];

export default ComplianceRoutes;
