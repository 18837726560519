import { lazy } from "react";

const WorkOrderDashboard = lazy(() => import("./WorkOrderDashboard"));

const WorkOrderRoutes = [
  {
    path: "/workorder",
    component: WorkOrderDashboard
  }
];

export default WorkOrderRoutes;
