import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { LOGOUT_SUCCESS } from "./LoginActions";
import { getApi, postApi } from "app/services/ApiService";
import { API_GET_USER_PROFILE, API_POST_USER_PROFILE } from "app/services/APIConstants";
import {
  NotificationManager
} from "react-notifications";
import Constants from 'app/services/Constants';

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    dispatch({
      type: USER_LOGGED_OUT
    });
    dispatch({ type: LOGOUT_SUCCESS })
    history.push({
      pathname: "/"
    });

  };
}
export function getProfile() {
  return dispatch => {
    const onFail = (error) => {
      console.log("data1 ======> ", error)
      const message = error && error instanceof String ? error : Constants.ERROR_COMMON
      NotificationManager.error(message, Constants.ERROR, 3000);
    }
    const onSuccess = (response) => {
      dispatch({
        type: GET_USER_PROFILE,
        data: response
      })
    }
    getApi(API_GET_USER_PROFILE, onSuccess, onFail, true);
  }
}

  export function setProfile(data) {
    return dispatch => {
      const onFail = (error) => {
        console.log("data1 ======> ", error)
        const message = error && error instanceof String ? error : Constants.ERROR_COMMON
        NotificationManager.error(message, Constants.ERROR, 3000);
      }
      const onSuccess = (response) => {

      }
      postApi(API_POST_USER_PROFILE, onSuccess, onFail, data);
    }
}