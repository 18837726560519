import { API_GET_GUEST_PASS, API_UPDATE_GUEST_PASS_STATUS } from "app/services/APIConstants";
import { getApi, postApi, putApi } from "app/services/ApiService";

export const SET_GUEST_PASS = "SET_GUEST_PASS"

export function loadGuestPass() {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (data) => {
      dispatch({
          type: SET_GUEST_PASS,
          data: data.guestPassDetails
      })
    }
    getApi(API_GET_GUEST_PASS, onSuccess, onFail);
  };
}

export function updateGuestPassStatus(guestPassID, status) {
  return (dispatch, getState) => {
    const onFail = (error) => {
      console.log("data ======> ", error)
    }
    const onSuccess = (data) => {
      dispatch(loadGuestPass())
    }
    let body = {
      "guestPassId": guestPassID,
      "status": status
    }
    putApi(API_UPDATE_GUEST_PASS_STATUS, onSuccess, onFail, body);
  };
}