export const TOURS_URL = "/patrol/tours";
export const TOUR_DETAIL_URL = "/patrol/tour-detail";
export const PATROL_INCIDENT_URL = "/patrol/incidents";
export const PATROL_INCIDENT_DETAIL_URL = "/patrol/incident/detail";
export const PATROL_POINT_TAGS_URL = "/patrol/point-tags";
export const PATROL_URL = "/patrol";
export const TOUR_ACTIVITY_URL = "/tour/activity";
export const PATROL_INCIDENT_TYPE_URL = "/patrol/incident-type";
export const TOUR_ACTIVITY_DETAIL_URL = "/tour/activity/detail";
export const COMPLIANCE_VIOLATION_TYPES_URL = "/compliance/violation-types";
export const VIOLATION_TYPE_DETAIL_URL = "/compliance/violations";
export const COMPLIANCE_INCIDENT_URL = "/compliance/incident";
export const COMPLIANCE_INCIDENT_DETAIL_URL = "/compliance/incidents/detail";
export const COMPLIANCE_ASSESSMENT_TEMPLATE_INFO = "/compliance/assessments/temp-info";
export const COMPLIANCE_ASSESSMENT_SECTIONS = "/compliance/assessments/sections";
export const COMPLIANCE_ASSESSMENT_ACTIVITY = "/compliance/activity";
export const COMPLIANCE_ASSESSMENT_ACTIVITY_DETAILS = "/compliance/activity/detail";
export const COMPLIANCE_LOTO_INVENTORY = "/compliance/loto-inventory";
