import {
  PEOPLE_SET_USERS
} from "../actions/PeopleActions";

const initialState = {};

const peopleReducer = function(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_SET_USERS: {
      return {
        ...state,
        users: action.data
      };
    }
    default: {
      return state;
    }
  }
};

export default peopleReducer;
