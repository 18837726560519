import { lazy } from "react";

const UsersRole = lazy(() => import("./UsersRole"));
const AdminPanel = lazy(() => import("./AdminPanel"));

const AdminPanelRoutes = [
  {
    path: "/admin-panel/users-role",
    component: UsersRole
  },
  {
    path: "/admin-panel",
    component: AdminPanel
  }
];

export default AdminPanelRoutes;
