import { lazy } from "react";
const Profile = lazy(() => import("./Profile"));

const ProfileRoutes = [
  {
    path: "/profile",
    component: Profile
  },
];

export default ProfileRoutes;
